// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++

import {ChangeDetectionStrategy, Component, OnDestroy} from "@angular/core";
import {untilComponentDestroyed} from 'ng2-rx-componentdestroyed';
import {QueryResource} from 'core-app/modules/hal/resources/query-resource';
import {OpTitleService} from "core-components/html/op-title.service";
import {WorkPackagesViewBase} from "core-app/modules/work_packages/routing/wp-view-base/work-packages-view.base";
import {take} from "rxjs/operators";
import {CausedUpdatesService} from "core-app/modules/boards/board/caused-updates/caused-updates.service";
import {DragAndDropService} from "core-app/modules/common/drag-and-drop/drag-and-drop.service";
import {BcfDetectorService} from "core-app/modules/bcf/helper/bcf-detector.service";
import {wpDisplayCardRepresentation} from "core-app/modules/work_packages/routing/wp-view-base/view-services/wp-view-display-representation.service";
import {WorkPackageTableConfigurationObject} from "core-components/wp-table/wp-table-configuration";
import {HalResourceNotificationService} from "core-app/modules/hal/services/hal-resource-notification.service";
import {WorkPackageNotificationService} from "core-app/modules/work_packages/notifications/work-package-notification.service";
import {scrollHeaderOnMobile} from "core-app/globals/global-listeners/top-menu-scroll";
import { WpTableExportModal } from "core-app/components/modals/export-modal/wp-table-export.modal";
import { WorkPackagesListService } from "core-app/components/wp-list/wp-list.service";
import { HttpClient } from "@angular/common/http";
import { LoadingIndicatorService } from "core-app/modules/common/loading-indicator/loading-indicator.service";
import {ExpressionService} from "../../../../../../common/expression.service";


declare let layui: any
@Component({
  selector: 'wp-list',
  templateUrl: './wp.list.component.html',
  styleUrls: ['./wp-list.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    /** We need to provide the wpNotification service here to get correct save notifications for WP resources */
    { provide: HalResourceNotificationService, useClass: WorkPackageNotificationService },
    DragAndDropService,
    CausedUpdatesService
  ]
})
export class WorkPackagesListComponent extends WorkPackagesViewBase implements OnDestroy {
  text = {
    'jump_to_pagination': this.I18n.t('js.work_packages.jump_marks.pagination'),
    'text_jump_to_pagination': this.I18n.t('js.work_packages.jump_marks.label_pagination'),
    'button_settings': this.I18n.t('js.button_settings')
  };

  /** Whether the title can be edited */
  titleEditingEnabled:boolean;

  /** Current query title to render */
  selectedTitle?:string;
  currentQuery:QueryResource;

  /** Whether we're saving the query */
  querySaving:boolean;

  /** Do we currently have query props ? */
  hasQueryProps:boolean;

  /** Listener callbacks */
  unRegisterTitleListener:Function;
  removeTransitionSubscription:Function;

  /** Determine when query is initially loaded */
  tableInformationLoaded = false;

  /** Project identifier of the list */
  projectIdentifier = this.$state.params['projectPath'] || null;

  /** An overlay over the table shown for example when the filters are invalid */
  showResultOverlay = false;

  /** Switch between list and card view */
  private _showListView:boolean = true;

  private readonly titleService:OpTitleService = this.injector.get(OpTitleService);
  private readonly bcfDetectorService:BcfDetectorService = this.injector.get(BcfDetectorService);
  readonly listServe:WorkPackagesListService = this.injector.get(WorkPackagesListService);
  readonly http:HttpClient = this.injector.get(HttpClient)
  readonly loading:LoadingIndicatorService = this.injector.get(LoadingIndicatorService)
  public readonly wpTableConfiguration:WorkPackageTableConfigurationObject = {
    dragAndDropEnabled: true
  };

  public firstLoad: boolean = false;
  public firstClick: boolean = false;
  public selectName: string = 'risc'
  ngOnInit() {
    super.ngOnInit();

    this.hasQueryProps = !!this.$state.params.query_props;

    // Load query initially unless it already was loaded
    if (!this.querySpace.initialized.hasValue()) {
      this.loadCurrentQuery();
    }

    // Load query on URL transitions
    this.updateQueryOnParamsChanges();

    // Mark tableInformationLoaded when initially loading done
    this.setupInformationLoadedListener();

    // Update title on entering this state
    this.unRegisterTitleListener = this.$transitions.onSuccess({to: 'work-packages.list'}, () => {
      if (this.selectedTitle) {
        this.titleService.setFirstPart(this.selectedTitle);
      }
    });

    this.querySpace.query.values$().pipe(
      untilComponentDestroyed(this)
    ).subscribe((query) => {
      // Update the title whenever the query changes
      console.log(query)
      this.updateTitle(query);
      this.currentQuery = query;
      

      // Update the visible representation
      if (this.deviceService.isMobile || this.wpDisplayRepresentation.valueFromQuery(query) === wpDisplayCardRepresentation) {
        this.showListView = false;
      } else {
        this.showListView = true;
      }

      this.cdRef.detectChanges();
    });
  }
  private HttpDownLoad(url:string) {
    let load = this.loading.indicator('table')
    load.start()
    console.log(load)
    return this.http.get(url, {
      responseType: 'blob'
    })
  }
  public downLoadPDF(id:any){
    return this.HttpDownLoad(`${window.location.origin}/api/v3/work_packages/${id}/export`)
  }
  getPdfHref(id:any, filename:any){
    let sucPromise = new Promise((reslve) => {

  
 
  this.downLoadPDF(id).subscribe ( (res:any) => {
      var file = new File([res], filename + '.pdf', { type: 'application/force-download' });
      let url = URL.createObjectURL(file)
      let a = document.createElement('a')
      a.download = filename + '.pdf'
      a.href = url
      document.body.appendChild(a)
      console.log(a)
      a.click()
     
      setTimeout( () => {

        a.remove()
      }, 2000)
 
      reslve()
    })

  })
  return sucPromise
  }
  ngOnDestroy():void {
    super.ngOnDestroy();
    this.unRegisterTitleListener();
    this.removeTransitionSubscription();
  }
  private allowAction(event:JQuery.TriggeredEvent, modelName:string, action:any) {
    if (this.authorisationService.can(modelName, action)) {
      return true;
    } else {
      event.stopPropagation();
      return false;
    }
  }
  public allowWorkPackageAction(event:JQuery.TriggeredEvent, action:any) {
    return this.allowAction(event, 'work_packages', action);
  }
  public download() {
    let completeList = [] as Array<any>
    let load = this.loading.indicator('table')
    load.start()
    this.listServe.getDownLoadId().forEach( (name,id) => {
      completeList.push( this.getPdfHref(id,name))
    })
    Promise.all(completeList).finally( () => {
      load.stop()
    })
  }
  public export($event:JQuery.TriggeredEvent) {
      // if (this.allowWorkPackageAction($event, 'representations')) {
      //   this.opModalService.show(WpTableExportModal, this.injector);
      // }
      // this.selectName = document.getElementsByClassName('panel')[0].getElementsByClassName('selected')
      let el = document.getElementsByClassName('panel')[0].getElementsByClassName('selected')[0] as any
      // this.selectName = el.innerText == 'RISC(Work)' ? 'inspection': 'risc'
      this.selectName = el.innerText == 'RISC(Work)' ? 'inspection': (el.innerText == 'RISC(EM)' ? 'RISC(EM)' : 'risc')
      window.open(location.origin + `/projects/${document.getElementsByName('current_project')[0].dataset.projectId}/export_all_risc_to_excel?risc_type=` + this.selectName)
      return true;
    }

  public setAnchorToNextElement() {
    // Skip to next when visible, otherwise skip to previous
    const selectors = '#pagination--next-link, #pagination--prev-link, #pagination-empty-text';
    const visibleLink = jQuery(selectors)
      .not(':hidden')
      .first();

    if (visibleLink.length) {
      visibleLink.focus();
    }
  }

  public allowed(model:string, permission:string) {
    return this.authorisationService.can(model, permission);
  }

  public saveQueryFromTitle(val:string) {
    if (this.currentQuery && this.currentQuery.persisted) {
      this.updateQueryName(val);
    } else {
      this.wpListService
        .create(this.currentQuery, val)
        .then(() => this.querySaving = false)
        .catch(() => this.querySaving = false);
    }
  }

  updateQueryName(val:string) {
    this.querySaving = true;
    this.currentQuery.name = val;
    this.wpListService.save(this.currentQuery)
      .then(() => this.querySaving = false)
      .catch(() => this.querySaving = false);
  }


  updateTitle(query:QueryResource) {
    let el = document.getElementsByClassName('panel')[0].getElementsByClassName('selected')[0] as any
    let selectName = el.innerText == 'RISC(Work)' ? `RISC(Work) - ${query.results.total} records`: (el.innerText == 'RISC(EM)' ? `RISC(EM) - ${query.results.total} records` : `RISC(Survey) - ${query.results.total} records`)
    if (query.persisted) {
      this.selectedTitle = query.name;
    } else {
      this.selectedTitle =  selectName
    }

    this.titleEditingEnabled = this.authorisationService.can('query', 'updateImmediately');

    // Update the title if we're in the list state alone
    if (this.$state.current.name === 'work-packages.list') {
      this.titleService.setFirstPart(this.selectedTitle);
    }
  }

  public refresh(visibly:boolean = false, firstPage:boolean = false):Promise<unknown> {
    let promise:Promise<unknown>;

    if (firstPage) {
      promise = this.loadCurrentQuery();
    } else {
      promise = this.wpListService.reloadCurrentResultsList();
    }

    if (visibly) {
      this.loadingIndicator = promise;
    }

    return promise;
  }

  public updateResultVisibility(completed:boolean) {
    this.showResultOverlay = !completed;
  }

  public set showListView(val:boolean) {
    this._showListView = val;
  }

  public get showListView():boolean {
    return this._showListView;
  }

  public bcfActivated() {
    return this.bcfDetectorService.isBcfActivated;
  }

  protected updateQueryOnParamsChanges() {
    // Listen for param changes
    this.removeTransitionSubscription = this.$transitions.onSuccess({}, (transition):any => {
      let options = transition.options();
      const params = transition.params('to');
      this.hasQueryProps = !!params.query_props;

      let newChecksum = this.wpListService.getCurrentQueryProps(params);
      let newId:string = params.query_id ? params.query_id.toString() : null;

      this.cdRef.detectChanges();

      // Avoid performing any changes when we're going to reload
      if (options.reload || (options.custom && options.custom.notify === false)) {
        return true;
      }

      this.wpListChecksumService
        .executeIfOutdated(newId,
          newChecksum,
          () => this.loadCurrentQuery());
    });
  }

  protected setupInformationLoadedListener() {
    this
      .querySpace
      .initialized
      .values$()
      .pipe(take(1))
      .subscribe(() => {
        this.tableInformationLoaded = true;
        this.cdRef.detectChanges();
      });
  }

  protected set loadingIndicator(promise:Promise<unknown>) {
    this.loadingIndicatorService.table.promise = promise;
  }

  protected loadCurrentQuery():Promise<unknown> {
    let that = this;
    return this.loadingIndicator =
      this.wpListService
        .loadCurrentQueryFromParams(this.projectIdentifier)
        .then(() => {
          // wang记号
          if (!that.firstLoad) {
            that.firstLoad = true;
            let elements = jQuery('.wp-query-menu--item-link');
            elements.on('click', function() {
              // elements.off('click');
              let {pathname, search} = this as any;
              let {pathname: urlPathname , search: urlSearch} = window.location as any;
              pathname = pathname.split('/').pop();
              urlPathname = urlPathname.split('/').pop();
              let clickAllTask = pathname === 'work_packages' && !search;
              let repeatClickAllTask = (urlPathname === 'work_packages' && !urlSearch) && clickAllTask;
              if ((clickAllTask && !that.firstClick) || repeatClickAllTask) {
                setTimeout(() => {
                  that.loadCurrentQuery();
                });
              }
              that.firstClick = true;
            })
          }
          this.querySpace.rendered.valuesPromise();
        });
  }
  //打开下载弹框
openDownLoadDialog(){
  let self = this
  layui.use(['jquery','layer','laydate', 'dropdown'], function() {
    let startTime = '',endTime='',state='';
    let date1 = new Date().toLocaleDateString().replace(/\//g,'-');
    let data2 = ExpressionService.lastMonth()
    let projectId:any = document.querySelector('meta[name=current_project]')
    var layer = layui.layer,$ = layui.$,
    laydate =  layui.laydate,dropdown = layui.dropdown;
    let el = document.getElementsByClassName('panel')[0].getElementsByClassName('selected')[0] as any
    let selectName = el.innerText == 'RISC(Work)' ? 'inspection': (el.innerText == 'RISC(EM)' ? 'RISC(EM)': 'risc')
    layer.open({
      content:'<div class="layui-form"><div class="layui-form-item"><div class="layui-inline"><label class="layui-form-label" style="width: 100px;">Period</label><div class="layui-inline" id="test6"><div class="layui-input-inline"><input type="text" autocomplete="off" id="test-startDate-1" class="layui-input" placeholder="From"></div><div class="layui-form-mid">-</div><div class="layui-input-inline"><input type="text" autocomplete="off" id="test-endDate-1" class="layui-input" placeholder="To"></div> </div></div></div><div class="layui-form-item"><div class="layui-inline"><label class="layui-form-label" style="width: 100px;"></label><button class="layui-btn" id="demo1">status<i class="layui-icon layui-icon-down layui-font-12"></i></button></div></div></div>',
      // area: ['400px', '400px'],
      title:'Export',
      btn: ['Confirm'],
      yes: function(index:any, layero:any){
        //do somethinghttp://127.0.0.1:3000/projects/1/export_all_risc_to_excel
        window.open(`/projects/${projectId.dataset.projectId}/export_all_risc_to_excel?from=${startTime}&to=${endTime}&state=${state}&risc_type=${selectName}`)
        layer.close(index); //如果设定了yes回调，需进行手工关闭
      }
    });
    laydate.render({
      elem: '#test6',
      range: '-'
      ,format: 'yyyy-MM-dd',
       value: `${data2} - ${date1}`
      ,isInitValue: true
      ,lang: 'en'
      ,done: function(value:any, date:any, endDate:any){
        console.log(date,endDate); //得到日期生成的值，如：2017-08-18
        startTime = date.year+'-'+date.month+'-'+date.date
        endTime = endDate.year+'-'+endDate.month+'-'+endDate.date
        $('#test-startDate-1').val(startTime)
        $('#test-endDate-1').val(endTime)
      }
    });
    self.http.get(`/api/v3/work_packages/risc_status`).toPromise().then((res:any) => {
   let arr:any = []
    res.forEach((item:any) => {
      arr.push({title:item.name,id:item.id})
     })
     dropdown.render({
      elem: '#demo1' //可绑定在任意元素中，此处以上述按钮为例
      ,data: arr
      ,id: 'demo1',
      //菜单被点击的事件
      ready: function(elemPanel:any, elem:any){
        elemPanel.css({ "height": "200px", "overflow": "auto",'overflow-x': 'hidden' });
      } 
      ,click: function(obj:any){
          state =obj.id
          this.elem.html(`${obj.title}<i class="layui-icon layui-icon-down layui-font-12"></i>`)
      }
    });
    })

  
})

}
}
