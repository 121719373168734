import { Component, OnInit,Input, NgZone, Output, EventEmitter } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { emit } from 'process';
import { DocumentsService } from '../../services/check.lists.service';
declare let laydate: any;


@Component({
  selector: 'check_list-create',
  templateUrl: './check_list-create.component.html',
  styleUrls: ['./check_list-create.component.sass']
})
export class Check_listCreateComponent implements OnInit{
  public fileRefNo:any
  public kind_id:any
  public projectId:any = document.getElementsByName('current_project')[0].dataset.projectId
  public checklistArr = ['', 'Checklist', 'Safety', 'Cleansing', "Weekly Environmental Walk Inspection", "Weekly Safety Walk Inspection","Monthly Safety", "Weekly Safety Walk Inspection New","Early Warning","Contractor's Notif.of CE","Interim Assessment",'MDM Setting',"Work Near Underground Permit"]
  public isFileRefNo:any
  public fileRefNoType:any
  public check_listsID:any
  public fileRefNoInfo:any
  public unique_date:any
  @Input() public template:any
  @Output() public closeEmit:EventEmitter<any> = new EventEmitter<any>()
  constructor(public service: DocumentsService,
    private zone: NgZone,
    public i18n: I18nService,) { }

  ngOnInit() {
    this.getFileRefNo(this.projectId)
    let unique_date = new Date()
    let month = unique_date.getUTCMonth() + 1 as any
    if(month<10) {
      month = '0' + month
    }
    this.unique_date = unique_date.getUTCFullYear() + '-' + month

  }

  getStringValue(value:any) {
    return JSON.stringify(value)
  }
  public getFileRefNo(project_id: string) {
    this.kind_id = location.search.split('?')[1].split('&')[0].split('=')[1]
    const type = this.checklistArr[this.kind_id];
    this.service.fileRefNo(project_id,type).subscribe((res:any) => {
      this.zone.run(() => {

          this.isFileRefNo = true;
          this.fileRefNo = res.data;
        console.log(res.data,'dddddddddd')
          if (res.data.values.length > 0) {
            this.fileRefNoType = this.getStringValue(res.data.values[0].value) || "";
          }
          // this.hook.globalVar.next([{ prefix:this.fileRefNo.prefix,type_value: this.fileRefNoType}]);

      });
    });
  }
  public renderDate() {
    console.log(document.getElementById('timeDate'),laydate)
    laydate.render({
      elem: `#timeDate`
      ,lang: 'en'
      ,show:true,
      type: 'month'
      ,value:this.unique_date,
      format: 'yyyy-MM'
      ,done:(value:any)=>{
        this.unique_date = value
      }
    });
  }
  public getFileRefNoInfo() {
    const obj_id = this.check_listsID;

    this.kind_id = location.search.split('?')[1].split('&')[0].split('=')[1]
    const obj_type =this.checklistArr[this.kind_id] ;
    this.service.fileRefNoInfo(obj_id, obj_type).subscribe((res:any) => {
      this.zone.run(() => {
        if (res.is_dwss_type) {
          this.isFileRefNo = true;
          this.fileRefNoInfo = res.data;
          this.fileRefNo = {};
          console.log(this.fileRefNoInfo)
        } else {
          this.getFileRefNo(this.projectId);
          this.isFileRefNo = false;
        }
      });
    });
  }
  public   newtemitemclick(e:any){
    console.log(e);
    let data = {
      template_id:this.template.id,
      name:this.template.name,
      description:'Environmental - Waste Water Inspection',
      check_list_kind_id : Number(this.kind_id)
    }
    this.service.newcheckLists(this.projectId.dataset.projectId,data).subscribe((res: any) => {
      this.check_listsID = res.id
      // this.dipschecklist()
      console.log(res)

    })

  }
  public saveFileRefNo() {

    const obj_type: string = 'CheckList' ;
    let fileRefNoType = JSON.parse(this.fileRefNoType)
    let data = {
      template_id:this.template.id,
      name:this.template.name,
      description:'Environmental - Waste Water Inspection',
      check_list_kind_id : Number(this.kind_id),

    } as any
    if(this.kind_id == 6) {
      data.unique_date = this.unique_date + '-01'
    }
    this.service.newcheckLists(this.projectId,data).subscribe((res: any) => {
      this.check_listsID = res.id
      // this.dipschecklist()
      console.log(res)
      if (res.result == "ok") {
      this.service
        .saveFileRefNo({
          obj_id: this.check_listsID,
          obj_type,
          prefix: this.fileRefNo.prefix,
          type_value: fileRefNoType,
        })
        .subscribe((res:any) => {
          if (res.result == "ok") {
            this.closeEmit.emit({type:'create', check_listsID:this.check_listsID})
            this.getFileRefNoInfo();
          } else {
            alert(res.reason);
          }
          // this.service.getCheckList(this.projectId.dataset.id,this.check_listsID).subscribe((res: any) => {
          //   // this.data.check_list.file_ref_no = res.check_list.file_ref_no
          //   // this.saveFileFlag = false
          // })
        });
      } else {
        alert(res.reson);
      }
    })

    }
}
