<div *ngIf="workPackage" class="work-package--single-view" [ngClass]="{'-can-have-columns' : enableTwoColumnLayout }">
  <div style="display: flex;margin-top: 20px; flex-wrap: wrap; justify-content: space-between;"
  *ngIf="riscpdfdowint">
  <a [href]='workPackageriscid' class="riscpdf">download-PDF</a>
  <button class="assignNextButton" style="background-color: rgb(49, 148, 214);" (click)='sharePDF()'>Share</button>
  <button class="assignNextButton" style="background-color: rgb(253, 142, 0);" [ngStyle]="{'background-color': canAssigntNext?'rgb(253, 142, 0)':'#eee', 'color': canAssigntNext?'black': '#aaa'}" (click)='assignNext()'>Assign
    Next</button>
</div>
  <div class="wp-new--subject-wrapper" *ngIf="workPackage.isNew">
    <editable-attribute-field [resource]="workPackage" [wrapperClasses]="'-no-label'" [fieldName]="'subject'">
    </editable-attribute-field>
  </div>

  <div class="wp-info-wrapper" *ngIf="isRiscForm">
    <wp-status-button *ngIf="!workPackage.isNew" [workPackage]="workPackage">
    </wp-status-button>
    <attribute-help-text [attribute]="'status'" [attributeScope]="'WorkPackage'" *ngIf="!workPackage.isNew">
    </attribute-help-text>

    <div class="work-packages--info-row" *ngIf="!workPackage.isNew">
      <span [textContent]="idLabel"></span>:
      <span [textContent]="text.infoRow.createdBy"></span>
      <!-- The space has to be in an extra span
      because otherwise the browser would add a second space after it -->
      <span>&nbsp;</span>
      <user-link class="user-link" [user]="workPackage.author"></user-link>.
      <span [textContent]="text.infoRow.lastUpdatedOn"></span>
      <span>&nbsp;</span>
      <op-date-time [dateTimeValue]="workPackage.updatedAt"></op-date-time>.
    </div>

    <wp-custom-actions [workPackage]="workPackage" class="custom-actions"></wp-custom-actions>
  </div>

  <div class="attributes-group -project-context __overflowing_element_container __overflowing_project_context"
    *ngIf="projectContext && projectContext.field" data-overflowing-identifier=".__overflowing_project_context">
    <div>
      <p class="wp-project-context--warning" [textContent]="text.project.required"></p>
      <div class="attributes-key-value" [ngClass]="{'-span-all-columns': descriptor.spanAll }"
        *ngFor="let descriptor of projectContext.field; trackBy:trackByName">
        <div class="attributes-key-value--key">
          <wp-replacement-label [fieldName]="descriptor.name">
            {{ descriptor.label }}
            <span class="required" *ngIf="descriptor.field.required && descriptor.field.writable">*</span>
          </wp-replacement-label>
          <attribute-help-text [attribute]="descriptor.name" [attributeScope]="'WorkPackage'"></attribute-help-text>
        </div>
        <div class="attributes-key-value--value-container">
          <editable-attribute-field [resource]="workPackage" [fieldName]="descriptor.name"></editable-attribute-field>
        </div>
      </div>
    </div>
  </div>

  <div class="attributes-group -project-context hide-when-print"
    *ngIf="!workPackage.isNew && projectContext && !projectContext.matches">
    <div>
      <p>
        <span [innerHTML]="projectContextText"></span>
        <br />
        <a [attr.href]="projectContext.href" class="project-context--switch-link" [textContent]="text.project.switchTo">
        </a>
      </p>
    </div>
  </div>

  <div *ngIf="workPackage.bcf">
    <bcf-wp-single-view [workPackage]="workPackage"></bcf-wp-single-view>
  </div>


  <!-- -----------------------------File Ref No----------------------------- -->
  <ng-container *ngIf="isFileRefNo">

    <div class="attributes-group description-group " [ngStyle]="{'display':fileRefNo.prefix  ? 'block' : 'none' }">
      <div class="attributes-group--header">
        <div class="attributes-group--header-container">
          <h3 class="attributes-group--header-text">{{I18n.t("js.file-ref-no.title")}}</h3>
        </div>
      </div>
      <div class="single-attribute work-packages--details--description">
        <div class="attribute-fileRefNo">
          <div class="-columns-2">
            <div class="attributes-key-value">
              <div class="attributes-key-value--key">
                <wp-replacement-label [fieldName]="fileRefNo_prefix">
                  {{I18n.t("js.file-ref-no.prefix")}}
                  <span class="required">*</span>
                </wp-replacement-label>
              </div>
              <div class="attributes-key-value--value-container">
                {{ fileRefNo.prefix }}
              </div>
            </div>
            <div class="attributes-key-value">
              <div class="attributes-key-value--key">
                <wp-replacement-label [fieldName]="fileRefNo_id">
                  {{I18n.t("js.file-ref-no.id")}}
                </wp-replacement-label>
              </div>
              <div class="attributes-key-value--value-container">
                -
              </div>
            </div>
          </div>
          <div class="-columns-2">
            <div class="attributes-key-value">
              <div class="attributes-key-value--key">
                <wp-replacement-label [fieldName]="fileRefNo_type">
                  {{I18n.t("js.file-ref-no.type")}}
                  <span class="required">*</span>
                </wp-replacement-label>
              </div>
              <div class="attributes-key-value--value-container">
                <select class="attribute-fileRefNo-select" [(ngModel)]="fileRefNoType"
                  (ngModelChange)="fileRefNoSelect()">
                  <option *ngFor="let item of fileRefNo.values; trackBy:trackByName" [value]="getStringValue(item.value)">
                    {{item.value.value}}
                  </option>
                </select>
              </div>
            </div>
            <div class="attributes-key-value">
              <div class="attributes-key-value--key">
                <wp-replacement-label [fieldName]="fileRefNo_suffix">
                  {{I18n.t("js.file-ref-no.suffix")}}
                </wp-replacement-label>
              </div>
              <div class="attributes-key-value--value-container">
                -
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="attributes-group description-group"
      [ngStyle]="{'display':fileRefNoInfo.file_ref_no  ? 'block' : 'none' }">
      <div class="attributes-group--header">
        <div class="attributes-group--header-container">
          <h3 class="attributes-group--header-text">{{I18n.t("js.file-ref-no.title")}}</h3>
        </div>
      </div>
      <div class="single-attribute work-packages--details--description">
        <div class="attribute-file-info">
          {{fileRefNoInfo.file_ref_no}}
        </div>
      </div>
    </div>

  </ng-container>

  <!-- 加载 -->
  <div *ngIf="isRiscForm && (workPackage.type.name == 'RISC' || workPackage.type.name ==  'RISC(inspection)'  || workPackage.type.name == 'RISC(EM)')" style="display: block;width: 100%;height: 300px;border: 1px solid rgba(0,0,0,0.3); position: relative;">
    <div id="singleviewriscloding" class="attributes-key-value__loading" *ngIf="signloding == i"
      style="position: absolute; top: 50%;left: 50%;transform: translate(-50%,-50%);">
      <div class="loading-indicator -compact">
        <div class="block-1"></div>
        <div class="block-2"></div>
        <div class="block-3"></div>
        <div class="block-4"></div>
        <div class="block-5"></div>
      </div>
    </div>
    <pdf-viewer [src]="pdfSrc" [render-text]="true" [autoresize]="true" [original-size]="false"
      style="display: block;width: 100%;height: 100%;" (after-load-complete)="callBackFn($event)"
      (error)="onError($event)"></pdf-viewer>
  </div>




  <!-- -----------------------------描述模块----------------------------- -->

  <div class="attributes-group description-group">
    <div class="attributes-group--header">
      <div class="attributes-group--header-container">
        <h3 class="attributes-group--header-text" [textContent]="text.fields.description"></h3>
      </div>
    </div>
    <div class="single-attribute work-packages--details--description">
      <editable-attribute-field [fieldName]="'description'"
                     [resource]="workPackage"
                     [workPackageData]="workPackage"
                     [isDropTarget]="true"
                     [wrapperClasses]="'-no-label'">
      </editable-attribute-field>
    </div>
  </div>

  <!--  --------------------------------------------------------------------- -->

  <ng-container *ngIf="workPackage.workPackageExcel">
    <div id="ss" style="width: 100%; height: 430px;"></div>
    <input *ngIf="excel_id==0" type="button" (click)="savePDF()" value="savePDF" class="button">
    <!-- <button (click)="import()">导入</button>
    <input type="file" id="file"> -->
    <ng-container *ngIf="isexcel">
      <div class="confirm-cancel">
        <div class="confirm-cancel-item" (click)="export()">
          <span>
            <i class="icon-checkmark"></i>
          </span>
        </div>
        <div class="confirm-cancel-item" (click)="cloneexp()">
          <span>
            <i class="icon-close"></i>
          </span>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <!-- 編輯pdf模塊 -->
  <div class="p-pdf" *ngIf="pdfShow">
    <div id="app" class="app"></div>
  </div>
  <div class="close" *ngIf="pdfShow">
    <span>x</span>
  </div>
  <!-- 遮罩层 -->
  <div id="documents-mask" (click)="closeMask()" *ngIf="pdfShow">
  </div>
  <div class="documents--loading" *ngIf="loadingShow">
    <div>
      <div class="documents-loading icon rotate"></div>
      <span class="loading-text">Loading</span>
    </div>
  </div>
  <!-- 提示 -->
  <div class="documents-toasts" *ngIf="toastShow">
    <div class="documents-toast">
      <div class="toast-container">
        <div class="documents-success icon"></div>
        <div class="toast-text">{{ I18n.t("js.documents.save_successed")}}</div>
      </div>
    </div>
  </div>

  <!-- -----------------------------内容模块----------------------------- -->
  <ng-container *ngIf="siteDiaryTable.length!==0">
    <div class="site-diary-table">
      <div class="table-header">
        <div class="table-row">
          <div class="table-row-column column__name">
            {{siteDiaryTitle === 'Plant' ? 'Type' : siteDiaryTitle}}
          </div>
          <div class="table-row-column column__code" *ngIf="isCode || siteDiaryTitle === 'Plant'">
            {{siteDiaryTitle === 'Plant' ? 'No.Working' : 'Code'}}
          </div>
          <div class="table-row-column column__text">
            {{siteDiaryTitle === 'Plant' ? 'No,ldle' : 'No.'}}
          </div>
        </div>
      </div>

      <div class="table-body">
        <!-- <div id="ss"  ></div> -->
        <ng-container *ngFor="let item of siteDiaryTable;let i = index">
          <div class="table-row" *ngIf="item.kind !== 'labour_contd'">
            <div class="table-row-column column__name">
              {{ item.name }}
            </div>
            <div class="table-row-column column__code" *ngIf="isCode || siteDiaryTitle === 'Plant'">
              <ng-container *ngIf="siteDiaryTitle !== 'Plant'">
                {{ item.code }}
              </ng-container>
              <ng-container *ngIf="siteDiaryTitle === 'Plant'">
                <div class="column-text" *ngIf="!item.isChangePlant" (click)="siteDiaryDblclick(i, 'Plant')">
                  <span *ngIf="item.code">{{ item.code }}</span>
                  <span *ngIf="!item.code">-</span>
                </div>
                <div class="column-input" *ngIf="item.isChangePlant">
                  <input type="text" [(ngModel)]="item.code" />
                  <div class="confirm-cancel">
                    <div class="confirm-cancel-item" (click)="siteDiaryBlur(i, 'Plant')">
                      <span>
                        <i class="icon-checkmark"></i>
                      </span>
                    </div>
                    <div class="confirm-cancel-item" (click)="siteDiaryCancel(i, 'Plant')">
                      <span>
                        <i class="icon-close"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="table-row-column column__text">
              <div class="column-text" *ngIf="!item.isChange" (click)="siteDiaryDblclick(i)">
                <span *ngIf="item.text">{{ item.text }}</span>
                <span *ngIf="!item.text">-</span>
              </div>
              <div class="column-input" *ngIf="item.isChange">
                <input type="text" [(ngModel)]="item.text" />
                <div class="confirm-cancel">
                  <div class="confirm-cancel-item" (click)="siteDiaryBlur(i)">
                    <span>
                      <i class="icon-checkmark"></i>
                    </span>
                  </div>
                  <div class="confirm-cancel-item" (click)="siteDiaryCancel(i)">
                    <span>
                      <i class="icon-close"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="site-diary-table" *ngIf="siteDiaryTitle === 'Labour'">
      <div class="table-header">
        <div class="table-row">
          <div class="table-row-column column__name">
            Labour con't
          </div>
          <div class="table-row-column column__code" *ngIf="isCode">
            Code
          </div>
          <div class="table-row-column column__text">
            No.
          </div>
        </div>
      </div>
      <div class="table-body">
        <ng-container *ngFor="let item of siteDiaryTable;let i = index">
          <div class="table-row" *ngIf="item.kind !== 'labour'">
            <div class="table-row-column column__name">
              {{ item.name }}
            </div>
            <div class="table-row-column column__code" *ngIf="isCode">
              {{ item.code }}
            </div>
            <div class="table-row-column column__text">
              <div class="column-text" *ngIf="!item.isChange" (click)="siteDiaryDblclick(i)">
                <span *ngIf="item.text">{{ item.text }}</span>
                <span *ngIf="!item.text">-</span>
              </div>
              <div class="column-input" *ngIf="item.isChange">
                <input type="text" [(ngModel)]="item.text" />
                <div class="confirm-cancel">
                  <div class="confirm-cancel-item" (click)="siteDiaryBlur(i)">
                    <span>
                      <i class="icon-checkmark"></i>
                    </span>
                  </div>
                  <div class="confirm-cancel-item" (click)="siteDiaryCancel(i)">
                    <span>
                      <i class="icon-close"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <!--  --------------------------------------------------------------------- -->

  <!-- -----------------------------人员信息----------------------------- -->

  <div *ngFor="let group of groupedFields; trackBy:trackByName" [hidden]="shouldHideGroup(group)"
    [attr.data-group-name]="group.name" [ngClass]="'__overflowing_' + group.id"
    [attr.data-overflowing-identifier]="'.__overflowing_' + group.id"
    class="attributes-group __overflowing_element_container">

    <ng-container wp-isolated-query-space *ngIf="group.isolated">
      <ndc-dynamic [ndcDynamicComponent]="attributeGroupComponent(group)" [ndcDynamicInputs]="{ workPackage: workPackage,
                                         group: group,
                                         query: group.query,
                                         groupedFields: groupedFields,is_project_admin: is_project_admin }">
      </ndc-dynamic>
    </ng-container>

    <ng-container *ngIf="!group.isolated && adminGroupRisc(group)">
      <div class="attributes-group--header">
        <div [ngClass]="{ 'attributes-sub': group.isSub }" class="attributes-group--header-container"
          *ngIf="!group.noTitle">
          <h3 class="attributes-group--header-text" [textContent]="group.name"></h3>
          <!-- <p style="font-size: 12px;margin: 5px 0;">{{text.principal}}: {{group.principal_name}}</p> -->
        </div>
      </div>

      <ndc-dynamic [ndcDynamicComponent]="attributeGroupComponent(group)" [ndcDynamicInjector]="injector"
        [ndcDynamicInputs]="{ workPackage: workPackage, group: group,groupimgdata:groupimgdata, signusername:signusername, signuserzhu: signuserzhu,
          groupedFields: groupedFields, is_project_admin: is_project_admin}">
      </ndc-dynamic>
    </ng-container>


  </div>
  <!-- -----------------------INSPECTION Google地图模块----------------------- -->
  <ng-container *ngIf=" workPackage.type.name == 'Inspection' && workPackage.id != 'new'">
    <div class="attributes-group--header">
      <div class="attributes-group--header-container">
        <h3 class="attributes-group--header-text" [textContent]="'Reported By'"></h3>
      </div>
    </div>
    <div class="attribute-fileRefNo">
      <div class="-columns-2">
        <div class="attributes-key-value">
          <div class="attributes-key-value--key">
            <wp-replacement-label>
              Reported by
              <span class="required">*</span>
            </wp-replacement-label>
          </div>
          <div class="attributes-key-value--value-container">
            {{workPackage.author.name}}
          </div>
        </div>
      </div>
      <div class="-columns-2">
        <div class="attributes-key-value">
          <div class="attributes-key-value--key">
            <wp-replacement-label>
              Report Date
              <span class="required">*</span>
            </wp-replacement-label>
          </div>
          <div class="attributes-key-value--value-container">
            <op-date-time [dateTimeValue]="workPackage.updatedAt"></op-date-time>
          </div>
        </div>
      </div>
      <div class="-columns-2">
        <div class="attributes-key-value">
          <div class="attributes-key-value--key">
            <wp-replacement-label>
              Reported at
              <span class="required">*</span>
            </wp-replacement-label>
          </div>
          <div class="attributes-key-value--value-container" style="display: flex;">
            <div style="display: flex; flex-direction: column; line-height: 40px;">
              <span>{{workPackage.address}}</span>
              <span>{{workPackage.latitude}},{{workPackage.longitude}}</span>
            </div>
            <div style="margin-left: 30px; display: flex; align-items: center;cursor:pointer" *ngIf="workPackage.latitude && workPackage.longitude" (click)='toShowGmap = true'>
              <svg t="1605769600249" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="3656" width="40" height="40">
                <path
                  d="M516.320553 65.913723C327.473365 66.759049 174.512778 221.085162 175.350878 409.954025c0.787526 177.21493 282.974557 496.596289 315.154731 532.417865a39.737529 39.737529 0 0 0 59.440119-0.2601c31.862273-36.110576 311.202653-357.991787 310.415127-535.213942-0.838101-188.861638-155.178664-341.822225-344.040302-340.984125z m2.196402 492.860961c-76.483906 0.339575-139.009102-61.614845-139.348677-138.105976-0.339575-76.491131 61.629295-139.001877 138.105976-139.334227 76.512806-0.3468 139.023552 61.60762 139.363127 138.091526 0.339575 76.498356-61.614845 139.001877-138.120426 139.348677z"
                  fill="#d81e06" p-id="3657"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <wp-gmap *ngIf="toShowGmap" [address]="workPackage.address" [latitude]="workPackage.latitude" [longitude]="workPackage.longitude" (clone)="toShowGmap = false"></wp-gmap>
    <!-- <div style="width:500px;height: 500px;" *ngIf="toShowGmap"></div> -->
  </ng-container>
  <!-- ----------------------------------------------------------------------- -->

  <div class="loading-indicator -compact" *ngIf="hide">
    <div class="block-1"></div>
    <div class="block-2"></div>
    <div class="block-3"></div>
    <div class="block-4"></div>
    <div class="block-5"></div>
  </div>
  <!--  --------------------------------------------------------------------- -->

</div>

<!-- google地图 -->
<!-- <div style="width:300px;height:300px" id="map"></div> -->

<div class="showwatermark" (click)="hidewatermark()">
  <span class="refresh">{{I18n.t("js.refresh_success")}}</span>
</div>


<!-- -----------------------------文件拖拽----------------------------- -->

<ng-container *ngIf="isRiscForm">
  <div class="work-packages--attachments attributes-group"
    *ngIf="workPackage.canAddAttachments || workPackage.hasAttachments">
    <div class="work-packages--attachments-container">
      <div class="attributes-group--header">
        <div class="attributes-group--header-container ">
          <h3 class="attributes-group--header-text" [textContent]="text.attachments.label" style="line-height: 2em;">
          </h3>
          <button class="watermarkbtn" (click)="refreshmark()" [disabled]='hide' *ngIf="showmark"
            id="showmarkbtn">{{I18n.t("js.refresh_watermark")}}</button>
        </div>
      </div>

      <ndc-dynamic [ndcDynamicComponent]="attachmentListComponent()"
        [ndcDynamicInputs]="{ resource: workPackage,is_project_admin:is_project_admin }">
      </ndc-dynamic>

      <ndc-dynamic [ndcDynamicComponent]="attachmentUploadComponent()"
        [ndcDynamicInputs]="{ resource: workPackage,is_project_admin:is_project_admin }">
      </ndc-dynamic>
    </div>
  </div>
</ng-container>


<!--  --------------------------------------------------------------------- -->

<!---------------------------------显示photolog带过来的图片------------------------------------->
<div class="showImgFromPhotoLog" *ngIf="showPhotolog">
      <!-- photo_log加载 -->
      <div *ngIf="isPhotoLog && workPackage.type.name == 'Inspection'" class="photologloading">
        <div class="loading-indicator loadingposition">
          <div class="block-1"></div>
          <div class="block-2"></div>
          <div class="block-3"></div>
          <div class="block-4"></div>
          <div class="block-5"></div>
        </div>
      </div>
      <!----------显示photo-log的图片------------->
     <ng-container *ngIf="Photo_log">
      <ul>
        <li *ngFor="let item of Photo_log">
          <img [src]="item.image_url" >
          <div class="closebtn" (click)="todelete(item.id)">
            <img [src]="imgOrigin" >
          </div>
        </li>
      </ul>
     </ng-container>
     
</div>


<!-- -----------------------------人员信息中REPLY MESSAGE----------------------------- -->
<ng-container *ngIf="workPackage.type.name == 'Task'">
  <div *ngFor="let group of realyFields; trackBy:trackByName" [hidden]="shouldHideGroup(group)"
    [attr.data-group-name]="group.name" [ngClass]="'__overflowing_' + group.id"
    [attr.data-overflowing-identifier]="'.__overflowing_' + group.id"
    class="attributes-group __overflowing_element_container">

    <ng-container wp-isolated-query-space *ngIf="group.isolated">
      <ndc-dynamic [ndcDynamicComponent]="attributeGroupComponent(group)" [ndcDynamicInputs]="{ workPackage: workPackage,
                                  group: group,
                                  query: group.query,
                                  groupedFields: groupedFields,
                                  is_project_admin:is_project_admin }">
      </ndc-dynamic>
    </ng-container>

    <ng-container *ngIf="!group.isolated && adminGroupRisc(group)">
      <div class="attributes-group--header">
        <div [ngClass]="{ 'attributes-sub': group.isSub }" class="attributes-group--header-container"
          *ngIf="!group.noTitle">
          <h3 class="attributes-group--header-text" [textContent]="group.name"></h3>
          <p *ngIf="workPackage.type.name == 'RISC' || workPackage.type.name ==  'RISC(inspection)'  || workPackage.type.name == 'RISC(EM)'" style="font-size: 12px;margin: 5px 0;">{{text.principal}}:
            {{group.principal_name}}</p>
        </div>
      </div>
      <ndc-dynamic [ndcDynamicComponent]="attributeGroupComponent(group)" [ndcDynamicInjector]="injector"
        [ndcDynamicInputs]="{ workPackage: workPackage, group: group,groupimgdata:groupimgdata, signusername:signusername, signuserzhu: signuserzhu,
          groupedFields: groupedFields, is_project_admin: is_project_admin}">
      </ndc-dynamic>
    </ng-container>
  </div>
</ng-container>

<!--  --------------------------------------------------------------------- -->
<!--  --------------------------------------------------------------------- -->

<div style="display: flex;margin-top: 20px; flex-wrap: wrap; justify-content: space-between;"
  *ngIf="riscpdfdowint">
  <a [href]='workPackageriscid' class="riscpdf">download-PDF</a>
  <button class="assignNextButton" style="background-color: rgb(49, 148, 214);" (click)='sharePDF()'>Share</button>
  <button class="assignNextButton" style="background-color: rgb(253, 142, 0);" [ngStyle]="{'background-color': canAssigntNext?'rgb(253, 142, 0)':'#eee', 'color': canAssigntNext?'black': '#aaa'}" (click)='assignNext()'>Assign
    Next</button>
</div>

<wp-single-assignNext *ngIf="assignNextdipsle" (clone)='assignNext($event)' [workPackage]='workPackage'
  [groupedFields]='groupedFields'></wp-single-assignNext>
<wp-single-sharePdf *ngIf="showShare" (outputEmitter)="sharePDF($event)" [workPackage]='workPackage'>
</wp-single-sharePdf>
