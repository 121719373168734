// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++

import { UserResource } from 'core-app/modules/hal/resources/user-resource';
import { WorkPackageResource } from 'core-app/modules/hal/resources/work-package-resource';
import { WorkPackageViewFocusService } from 'core-app/modules/work_packages/routing/wp-view-base/view-services/wp-view-focus.service';
import { StateService } from '@uirouter/core';
import { TypeResource } from 'core-app/modules/hal/resources/type-resource';
import { Component, Injector, OnInit } from '@angular/core';
import { WorkPackageViewSelectionService } from 'core-app/modules/work_packages/routing/wp-view-base/view-services/wp-view-selection.service';
import { States } from 'core-components/states.service';
import { KeepTabService } from 'core-components/wp-single-view-tabs/keep-tab/keep-tab.service';
import { FirstRouteService } from "core-app/modules/router/first-route-service";
import { WorkPackageSingleViewBase } from "core-app/modules/work_packages/routing/wp-view-base/work-package-single-view.base";
import { BackRoutingService } from "core-app/modules/common/back-routing/back-routing.service";
import { HookService } from "core-app/modules/plugins/hook-service";
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';
import { HttpClient } from '@angular/common/http';
import { HandlerCheckObserveService } from 'core-app/components/HandlerCheckObserve.service';


@Component({
  templateUrl: './wp-full-view.html',
  selector: 'wp-full-view-entry',
  // Required class to support inner scrolling on page
  host: { 'class': 'work-packages-page--ui-view' }
})
export class WorkPackagesFullViewComponent extends WorkPackageSingleViewBase implements OnInit {

  // Watcher properties
  public isWatched: boolean;
  public displayWatchButton: boolean;
  public watchers: any;

  // Properties
  public type: TypeResource;
  public author: UserResource;
  public authorPath: string;
  public authorActive: boolean;
  public attachments: any;

  // More menu
  public permittedActions:any;
  public actionsAvailable:any;
  public triggerMoreMenuAction:Function;
  public isShow:boolean=false;

  public isRiscForm:boolean=true
  public backRoutingService:BackRoutingService = this.injector.get(BackRoutingService);

  public phone_demo = true
  public phone_demo_pdf = true
  constructor(public injector: Injector,
    public states: States,
    public firstRoute: FirstRouteService,
    public keepTab: KeepTabService,
    public wpTableSelection: WorkPackageViewSelectionService,
    public wpTableFocus: WorkPackageViewFocusService,
    readonly $state: StateService,
    protected hook: HookService,
    readonly http: HttpClient,
    public handleCheck: HandlerCheckObserveService) {
    super(injector, $state.params['workPackageId']);
  }

  public wptext = {
    attachments: {
      label: this.I18n.t("js.label_attachments")
    },
    project: {
      required: this.I18n.t("js.project.required_outside_context"),
      context: this.I18n.t("js.project.context"),
      switchTo: this.I18n.t("js.project.click_to_switch_context")
    },

    fields: {
      description: this.I18n.t("js.work_packages.properties.description")
    },
    infoRow: {
      createdBy: this.I18n.t("js.label_created_by"),
      lastUpdatedOn: this.I18n.t("js.label_last_updated_on")
    },
    principal: this.I18n.t("js.work_packages.properties.principal")
  };

  public pdfSrc: String

  ngOnInit(): void {
    this.handleCheck.eventBus.subscribe( (res:any) => {
        if(res === 'refreshPdf') {
          jQuery('#riscloding').css('display', 'block')
          this.pdfSrc = `${window.location.origin}/api/v3/work_packages/${this.workPackageId}/export_mini?${Math.random()}`
        }
    })
    this.observeWorkPackage();
  }

  ngAfterViewInit() {
    // setTimeout(() => { this.jqdome() }, 1000);


  }

  jqdome() {
    let thar = this
    console.log('????', jQuery("#riscformpdf"))
    jQuery(document).ready(function () {
      jQuery("#riscformpdf").click(function () {
        jQuery('#riscloding').css('display', 'block')
        console.log('riscformpdf')
        thar.pdffunc()
      });
    })
  }

  public refresh() {
    jQuery('#riscloding').css('display', 'block')
    this.pdfSrc = `${window.location.origin}/api/v3/work_packages/${this.workPackageId}/export?${Math.random()}`
  }
  public pdffunc() {
    console.log(`${this.workPackageId}`)
    this.pdfSrc = `${window.location.origin}/api/v3/work_packages/${this.workPackageId}/export_mini?${Math.random()}`
  }

  protected initializeTexts() {
    super.initializeTexts();
    this.pdffunc()
    this.text.full_view = {
      button_more: this.I18n.t('js.button_more')
    };
  }

  protected init() {
    super.init();
    this.http.get(`/api/v3/work_packages/${this.workPackage.id}`).subscribe((data: any) => {
      this.workPackage.lockVersion = data.lockVersion
      this.handleCheck.eventBus.emit('workpackgeChange')
    })
    if (this.workPackage.$links.type.title == "RISC" || this.workPackage.$links.type.title ==  "RISC(inspection)" || this.workPackage.$links.type.title ==  "RISC(EM)") {
      console.log('???????', this.workPackage)
      this.phone_demo = window.location.search.indexOf('phone_demo') != -1 ? false : true
      if(!this.phone_demo){
        console.log(jQuery('#main'))
        this.phone_demo_pdf = window.location.search.indexOf('phone_demo_pdf') != -1 ? false : true
        jQuery('#top-menu').css('display','none')
        jQuery('#main').css('margin-top','0')
        jQuery('.work-packages--show-view').css('padding','0')
      }
      this.isRiscForm = false
    }
    this.workPackage['isRiscForm'] = this.isRiscForm
    console.log(this.isRiscForm)
    // Set Focused WP
    this.wpTableFocus.updateFocus(this.workPackage.id!);

    this.setWorkPackageScopeProperties(this.workPackage);
    this.text.goBack = this.I18n.t('js.button_back');

  }

  public goBack() {
    this.backRoutingService.goBack();
  }
  private setWorkPackageScopeProperties(wp: WorkPackageResource) {
    this.isWatched = wp.hasOwnProperty('unwatch');
    this.displayWatchButton = wp.hasOwnProperty('unwatch') || wp.hasOwnProperty('watch');

    // watchers
    if (wp.watchers) {
      this.watchers = (wp.watchers as any).elements;
    }

    // Type
    this.type = wp.type;

    // Author
    this.author = wp.author;
    this.authorPath = this.author.showUserPath as string;
    this.authorActive = this.author.isActive;

    // Attachments
    this.attachments = wp.attachments.elements;
  }

  public get idLabel() {
    return `#${this.workPackage.id}`;
  }

  public attachmentListComponent() {
    // we take the last registered group component which means that
    // plugins will have their say if they register for it.
    return this.hook.call("workPackageAttachmentListComponent", this.workPackage).pop() || null;
  }

  public attachmentUploadComponent() {
    // we take the last registered group component which means that
    // plugins will have their say if they register for it.
    return this.hook.call("workPackageAttachmentUploadComponent", this.workPackage).pop() || null;
  }

  callBackFn(pdf: PDFDocumentProxy) {
    //  使用“ pdf”执行任何操作
    console.log(pdf)
    jQuery('#riscloding').css('display', 'none')
  }
  onProgress(progressData: PDFProgressData) {
    console.log('进行中？', progressData)
    // do anything with progress data. For example progress indicator
  }

}
