import { ChangeDetectorRef, OnDestroy, Component, OnInit, Renderer2, Inject } from "@angular/core";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { Title,DomSanitizer } from "@angular/platform-browser";
import { GridInitializationService } from "core-app/modules/grids/grid/initialization.service";
import { PathHelperService } from "core-app/modules/common/path-helper/path-helper.service";
import { GridResource } from "core-app/modules/hal/resources/grid-resource";
import { GridAddWidgetService } from "core-app/modules/grids/grid/add-widget.service";
import { GridAreaService } from "core-app/modules/grids/grid/area.service";
import { CurrentProjectService } from "core-components/projects/current-project.service";
import { GRID_PROVIDERS } from "core-app/modules/grids/grid/grid.component";
import { HttpClient } from "@angular/common/http";

@Component({
  templateUrl: "./my-page.component.html",
  styleUrls: ["./my-page.component.sass"],
  providers: GRID_PROVIDERS
})
export class MyPageComponent implements OnInit, OnDestroy {
  // 项目数据
  public projectList: any;
  public showBack: any = false;
  public html: any;
  public text = {
    title: this.i18n.t(`js.${this.i18nNamespace()}.label`),
    html_title: this.i18n.t(`js.${this.i18nNamespace()}.label`)
  };
  public loadingList: boolean[] = [];

  constructor(
    readonly gridInitialization: GridInitializationService,
    // not used in the base class but will be used throughout the subclasses
    readonly pathHelper: PathHelperService,
    readonly currentProject: CurrentProjectService,
    readonly i18n: I18nService,
    readonly cdRef: ChangeDetectorRef,
    readonly title: Title,
    readonly domSanitizer:DomSanitizer,
    readonly addWidget: GridAddWidgetService,
    readonly renderer: Renderer2,
    readonly areas: GridAreaService,
    readonly http: HttpClient
  ) {}

  public grid: GridResource;

  private getText() {
    this.http.get("/api/v3/onboarding").subscribe(res => {
      console.log("getText", res);
      let obj: any = res;
      if (obj.result === "ok") {
        this.html = this.domSanitizer.bypassSecurityTrustHtml(obj.data)
      }
    });
  }

  private loadProjectData() {
    this.http.get("/api/v3/projects/overview").subscribe(res => {
      console.log("loadProjectData", res);
      let obj: any = res;
      if (obj.result === "Success") {
        this.projectList = obj.data;
        this.loadingList = new Array(obj.data.length).fill(true);
        const currentTime = this.formatDate(new Date())
        obj.data.forEach((item:any, index:number)=>{
          this.http.get(`/api/v3/projects/${item.id}/overviews/risc_overview?from=${item.created_at}&to=${currentTime}`).subscribe((res:any) => {
            this.projectList[index].risc_overview = res;
            this.loadingList[index] = false;
            this.cdRef.detectChanges();
          })
        })
      }
    });
    this.gridInitialization.initialize(this.gridScopePath()).then(grid => {
      this.grid = grid;
      this.cdRef.detectChanges();
    });
  }
  formatDate(date:any) {
    // 获取年份、月份和日期
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要加1，并且补0
    const day = date.getDate().toString().padStart(2, '0'); // 日期补0

    // 组合成YYYY-MM-DD格式
    return `${year}-${month}-${day}`;
  }
  ngOnInit() {
    this.renderer.addClass(document.body, "widget-grid-layout");
    this.loadProjectData();
    this.setHtmlTitle();
    this.getText()

    let bool = this.getUrlParam('first_time_user')
    if(bool){
      this.showBack = true
    }else{
      this.showBack = false
    }
    console.log(this.showBack)

  }
  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, "widget-grid-layout");
  }

  private setHtmlTitle() {
    this.title.setTitle(this.text.html_title);
  }
  protected i18nNamespace(): string {
    return "my_page";
  }
  getUrlParam(name:any) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if(r != null){
        return decodeURIComponent(r[2]);
    }
    return null;//返回参数值
};

  protected gridScopePath(): string {
    return this.pathHelper.myPagePath();
  }

  close(){
    this.showBack = false;
    window.history.replaceState(null,'',window.location.href.split('?')[0])
  }
}
