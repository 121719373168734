// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++

import {Component, Inject, OnDestroy} from '@angular/core';
import {StateService, Transition} from '@uirouter/core';
import {WorkPackageCacheService} from 'core-components/work-packages/work-package-cache.service';
import {WorkPackageResource} from 'core-app/modules/hal/resources/work-package-resource';
import {componentDestroyed} from 'ng2-rx-componentdestroyed';
import {takeUntil} from 'rxjs/operators';
import {I18nService} from 'core-app/modules/common/i18n/i18n.service';
import {HandlerCheckObserveService} from '../../HandlerCheckObserve.service'
import { HttpClient } from '@angular/common/http';

@Component({
  templateUrl: './overview-tab.html',
  selector: 'wp-overview-tab',
})
export class WorkPackageOverviewTabComponent implements OnDestroy {
  public workPackageId:string;
  public workPackage:WorkPackageResource;
  public tabName = this.I18n.t('js.label_latest_activity');
  public isRiscForm = true
  public reSet = true
  public msg = ''
  public constructor(readonly I18n:I18nService,
                     readonly $state:StateService,
                     readonly http: HttpClient,
                     readonly wpCacheService:WorkPackageCacheService,
                     public handlerCheck: HandlerCheckObserveService) {

    this.workPackageId = this.$state.params.workPackageId;
    wpCacheService.loadWorkPackage(this.workPackageId)
      .values$()
      .pipe(
        takeUntil(componentDestroyed(this))
      )
      .subscribe((wp) => this.workPackage = wp);
  }

  ngOnInit(){
    let pathname = window.location.pathname
    this.handlerCheck.eventBus.subscribe( (mes:any) => {  
      if(mes == 'handleChange') {
        this.msg = mes
        this.reSet = false
        console.log(mes)
        this.init()
      }
    })
    if((this.workPackage.$links.type.title == "RISC" || this.workPackage.$links.type.title ==  "RISC(inspection)" || this.workPackage.$links.type.title ==  "RISC(EM)") && pathname.split('/')[5]=='form'){
      this.isRiscForm = false
      this.workPackage['isRiscForm'] = false
    }else{
      this.isRiscForm = true
      this.workPackage['isRiscForm'] = true
    }
    
    console.log('WorkPackagesFullViewComponent=====>',this.workPackage)

    console.log('WorkPackageOverviewTabComponent=============>',this.$state.params)

  }
  init() {

 
    this.wpCacheService.loadWorkPackage(this.workPackageId)
    .values$()
    .pipe(
      takeUntil(componentDestroyed(this))
    )
    .subscribe((wp) => {
      this.workPackage = wp


      this.http.get(`/api/v3/work_packages/${this.workPackage.id}`).subscribe( (msg:any) => {
        console.log(msg)
        this.workPackage._attributeGroups = msg._attributeGroups
        this.workPackage.lockVersion = msg.lockVersion
        let pathname = window.location.pathname
        if((this.workPackage.$links.type.title == "RISC" || this.workPackage.$links.type.title == "RISC(inspection)" || this.workPackage.$links.type.title == "RISC(EM)") && pathname.split('/')[5]=='form'){
          this.isRiscForm = false
          this.workPackage['isRiscForm'] = false
        }else{
          this.isRiscForm = true
          this.workPackage['isRiscForm'] = true
        }
        console.log(this.workPackage)
        if(this.msg == 'handleChange') {

          this.handlerCheck.eventBus.emit('overViewTab')
          this.msg  = ''
        }
        this.reSet = true
      })

    });
  }
  ngOnDestroy() {
    // Nothing to do
  }
}
