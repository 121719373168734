// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  NgZone,
  Attribute,
} from "@angular/core";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { PathHelperService } from "core-app/modules/common/path-helper/path-helper.service";
import { componentDestroyed } from "ng2-rx-componentdestroyed";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import { debugLog } from "../../../helpers/debug_output";
import { CurrentProjectService } from "../../projects/current-project.service";
import { States } from "../../states.service";
import { WorkPackageResource } from "core-app/modules/hal/resources/work-package-resource";
import { CurrentUserService } from "core-components/user/current-user.service";
import { Photo_logService } from './../../../modules/photo_log/services/photo_log.service';
import { HalResourceEditingService } from "core-app/modules/fields/edit/services/hal-resource-editing.service";
import { WorkPackageCacheService } from "../work-package-cache.service";
import { input, InputState } from "reactivestates";
import { DisplayFieldService } from "core-app/modules/fields/display/display-field.service";
import { DisplayField } from "core-app/modules/fields/display/display-field.module";
import { QueryResource } from "core-app/modules/hal/resources/query-resource";
import { HookService } from "core-app/modules/plugins/hook-service";
import { WorkPackageChangeset } from "core-components/wp-edit/work-package-changeset";
import { from, Subject } from "rxjs";
import { randomString } from "core-app/helpers/random-string";
import { BrowserDetector } from "core-app/modules/common/browser/browser-detector.service";
import { PortalCleanupService } from "core-app/modules/fields/display/display-portal/portal-cleanup.service";
import { HalResourceService } from "core-app/modules/hal/services/hal-resource.service";
import { HttpClient } from "@angular/common/http";
import { any } from '@uirouter/core';
import { DocumentsService } from '../../../modules/documents/services/documents.service';
// import PSPDFKit from 'pspdfkit';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import {HandlerCheckObserveService} from '../../HandlerCheckObserve.service'
//google地图
import { Loader } from "@googlemaps/js-api-loader"


export interface FieldDescriptor {
  name: string;
  label: string;
  field?: DisplayField;
  fields?: DisplayField[];
  spanAll: boolean;
  multiple: boolean;
}

export interface GroupDescriptor {
  principal_name: string;
  name: string;
  id: string;
  members: FieldDescriptor[];
  query?: QueryResource;
  relationType?: string;
  isolated: boolean;
  type: string;
  status?: any;
  real_principal_name?: any
}

export interface ResourceContextChange {
  isNew: boolean;
  schema: string | null;
  project: string | null;
}

export const overflowingContainerAttribute = "overflowingIdentifier";

declare var GC: any;
declare var saveAs: any;



@Component({
  templateUrl: "./wp-single-view.html",
  selector: "wp-single-view",
  styleUrls: ["./wp-single-view.component.sass"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PortalCleanupService]
})
export class WorkPackageSingleViewComponent implements OnInit, OnDestroy {
  @Input() public workPackage: WorkPackageResource;

  /** Should we show the project field */
  @Input() public showProject: boolean = false;
  private currentUser: CurrentUserService = this.injector.get(CurrentUserService);

  //是否可以修改
  public isPersonnel: boolean = false;

  //显示邮箱
  public substance: boolean = false;
  public lists: any[] = [];
  //点击分享传给子组件的pdfUrl
  public pdfSrcs: any;

  public spread: any;
  public excelIo: any;
  public sheet: any;
  public instance: any;
  public isexcel: boolean = false;
  public excel_id: number;
  public pdfShow: any = false;
  public Arraybuffer: any;
  public showmark:boolean = false
  public showPhotolog:boolean=false
  public canAssigntNext = true

  //创建前数据
  public fileRefNo: any = {};
  //创建后数据
  public fileRefNoInfo: any = {};

  //是否系统管理员
  public is_project_admin: boolean = false;
  public is_author: any;

  //是否显示 fileRefNo
  public isFileRefNo: boolean = false;
  // fileRefNo type
  public fileRefNoType: string;
  //旧的type
  public oldType: string;

  // Grouped fields returned from API
  public groupedFields: GroupDescriptor[] = [];
  
  //放置REPLY MESSAGE 
  public realyFields: GroupDescriptor[] = [];

  public siteDiaryTable: any = [];

  public siteDiaryTitle: string;
  public lockVersion: number = 0;
  public tableSchema: any = [];
  public isCode: boolean = false;
  public loadingShow: boolean = false
  public toastShow: boolean = false

  //riscpdf
  public pdfSrc: any
  public isRiscForm: any
  public isPhotoLog:any
  public imgOrigin = `${location.origin}/images/close.png`
  public assignNextdipsle: boolean = false

  //sharePDF
  public showShare: boolean = false
  // State updated when structural changes to the single view may occur.
  // (e.g., when changing the type or project context).
  public resourceContextChange = new Subject<ResourceContextChange>();

  // Project context as an indicator
  // when editing the work package in a different project
  public projectContext: {
    matches: boolean;
    href: string | null;
    field?: FieldDescriptor[];
  };
  public text = {
    attachments: {
      label: this.I18n.t("js.label_attachments")
    },
    project: {
      required: this.I18n.t("js.project.required_outside_context"),
      context: this.I18n.t("js.project.context"),
      switchTo: this.I18n.t("js.project.click_to_switch_context")
    },

    fields: {
      description: this.I18n.t("js.work_packages.properties.description")
    },
    infoRow: {
      createdBy: this.I18n.t("js.label_created_by"),
      lastUpdatedOn: this.I18n.t("js.label_last_updated_on")
    },
    principal: this.I18n.t("js.work_packages.properties.principal")
  };

  protected firstTimeFocused: boolean = false;
  public $element: JQuery;
  public siteDiaryTimeTable: any = {};

  //risc
  public groupimgdata: any = {}
  public signusername: any
  public riscpdfdowint = false
  public workPackageriscid: any
  public signuserzhu: any

  public from = 0
  public customFieldGroup: any = {}
  public Photo_log:any=[]
  public imageArr:any=[]
  // 菜单
  public menuArr:any = [
    'Works Category',
    'Document Reference',
    '(2) Work to be inspected/surveyed',
    '(3) Date & Time',
    '(5) Date & Time',
   ]

  public googlelist = [
    {name:'Reported by',data:'SL - WAN KA WAI'},
    {name:'Report Date',data:'2020-11-06 16:01'},
    {name:'Reported at',data:{
      name:'1-25 A Kung Ngam Rd',
      map:'22.279382, 114.231502'
    }}]
  public toShowGmap:boolean = false
  public checklistArr = ['Checklist','Safety','Cleansing']
  public checklistTypeArr = ['ChecklistType','SafetyType','CleansingType']
  public authorJudge:any = ['Document Reference','(1) Location of Work','(2) Work to be inspected/surveyed','(2) Date & Time','(3) Work Proposed after Approval of (2)','(3) Date & Time',
  "(4) Remarks (if this is a re-submission state work carried out since last inspection/survey)",'(4) Date & Time']
  private authorRole:any = null

  constructor(
    readonly I18n: I18nService,
    protected currentProject: CurrentProjectService,
    protected PathHelper: PathHelperService,
    protected states: States,
    protected halEditing: HalResourceEditingService,
    protected halResourceService: HalResourceService,
    protected displayFieldService: DisplayFieldService,
    protected wpCacheService: WorkPackageCacheService,
    protected hook: HookService,
    protected injector: Injector,
    protected cdRef: ChangeDetectorRef,
    public service: DocumentsService,
    public phservice:Photo_logService,
    readonly elementRef: ElementRef,
    readonly cleanupService: PortalCleanupService,
    readonly http: HttpClient,
    private zone: NgZone,
    readonly browserDetector: BrowserDetector,
    public handleCheck: HandlerCheckObserveService
  ) { }


  public manipulationData(newChange: WorkPackageChangeset) {

    // let pristineResource = newChange.pristineResource;

    // if (pristineResource) {
    //   let table = pristineResource.table;
    //   if (table) {
    //     let tablePayload = { ...table.payload };
    //     let tableSchema = table.schema;
    //     if (tablePayload) {
    //       delete tablePayload.type;
    //       Object.assign(newChange.pristineResource, tablePayload);
    //     }
    //     if (tableSchema) {
    //       Object.assign(newChange.schema, tableSchema);
    //     }
    //   }
    // }

    // if (this.workPackage.type.name === 'Site diary item') {
    //   if (pristineResource.table) {
    //     if (pristineResource.table.payload) {
    //       let table: any = [];
    //       for (let key of Object.keys(pristineResource.table.payload)) {
    //         // console.log(key);
    //         if (newChange.schema[key] && key !== "type") {
    //           if (!this.isCode) {
    //             if (typeof newChange.schema[key].code === 'string' && newChange.schema[key].code.length > 0) {
    //               this.isCode = true;
    //             }
    //           }
    //           table.push({
    //             kind: newChange.schema[key].kind,
    //             name: newChange.schema[key].name,
    //             key: key,
    //             code: newChange.schema[key].code,
    //             text: newChange.pristineResource[key].toString()
    //           });
    //         }
    //       }
    //       // console.log('pristineResource.table.payload',pristineResource.table.payload);
    //       // console.log('table',table);
    //       this.tableSchema = pristineResource.table.schema;
    //       this.siteDiaryTitle = this.workPackage.subject;
    //       this.siteDiaryTable = table;
    //     }
    //   }
    // }

    // if (newChange.schema.template) {
    //   if (newChange.schema.template.$source) {
    //     if (newChange.schema.template.$source._embedded) {
    //       if (newChange.schema.template.$source._embedded.schema) {
    //         let template = newChange.schema.template.$source._embedded.schema.template;
    //         let templateSchema = template._embedded.allowedValues[0].schema;
    //         this.tableSchema = templateSchema;
    //         // console.log("templateSchema",templateSchema);
    //         if (template) {
    //           if (templateSchema) {
    //             Object.assign(newChange.schema, templateSchema);
    //           }
    //         }
    //       }
    //     }
    //   }

    //   let formSelect = {
    //     _type: "WorkPackageFormAttributeGroup",
    //     name: "Select Form",
    //     attributes: ["template"]
    //   };
    //   let formTemplate = [
    //     {
    //       _type: "WorkPackageFormAttributeGroup",
    //       name: "Form",
    //       attributes: ["form_name", "client_department", "contract_no", "contract_title"]
    //     },
    //     {
    //       _type: "WorkPackageFormAttributeGroup",
    //       name: "Weather",
    //       attributes: ["am", "pm", "mm"]
    //     },
    //     {
    //       _type: "WorkPackageFormAttributeGroup",
    //       name: "Date",
    //       noTitle: true,
    //       attributes: ["form_date", "day", "day_counted"]
    //     },
    //     {
    //       _type: "WorkPackageFormAttributeGroup",
    //       name: "warning Signal",
    //       noTitle: true,
    //       attributes: ["thunderstorm", "rainstorm", "tripical_cyclone"]
    //     },
    //     {
    //       _type: "WorkPackageFormAttributeGroup",
    //       name: "instruclions",
    //       noTitle: true,
    //       attributes: ["instruclions"]
    //     },
    //     {
    //       _type: "WorkPackageFormAttributeGroup",
    //       name: "comments",
    //       noTitle: true,
    //       attributes: ["comments"]
    //     },
    //     {
    //       _type: "WorkPackageFormAttributeGroup",
    //       name: "utilities",
    //       noTitle: true,
    //       attributes: ["utilities"]
    //     },
    //     {
    //       _type: "WorkPackageFormAttributeGroup",
    //       name: "progress",
    //       noTitle: true,
    //       attributes: ["progress"]
    //     },
    //     {
    //       _type: "WorkPackageFormAttributeGroup",
    //       name: "visitor",
    //       noTitle: true,
    //       attributes: ["visitor"]
    //     },
    //     {
    //       _type: "WorkPackageFormAttributeGroup",
    //       name: "accidents",
    //       noTitle: true,
    //       attributes: ["accidents"]
    //     },
    //     {
    //       _type: "WorkPackageFormAttributeGroup",
    //       name: "remarks",
    //       noTitle: true,
    //       attributes: ["remarks"]
    //     }
    //   ];

    //   if (!newChange.schema._attributeGroups.some((item: any) => item.key == "Select Form")) {
    //     console.log('我和你打赌不走这里', newChange.schema._attributeGroups.some((item: any) => item.key == "Select Form"));

    //     newChange.schema._attributeGroups = [formSelect, ...formTemplate, ...newChange.schema._attributeGroups];
    //   }
    // }

    //excel
    if (!this.workPackage.workPackageExcel) {
      if (newChange.schema.excel) {
        if (newChange.schema.excel.$source) {
          if (newChange.schema.excel.$source._embedded) {
            if (newChange.schema.excel.$source._embedded.schema) {
              let excel = newChange.schema.excel.$source._embedded.schema.excel;
              let excelSchema = excel._embedded.allowedValues[0].schema;
              this.tableSchema = excelSchema;
              if (excel) {
                if (excelSchema) {
                  Object.assign(newChange.schema, excelSchema);
                }
              }
            }
          }
        }
        let formSelect = {
          _type: "WorkPackageFormAttributeGroup",
          name: "Select Form",
          attributes: ["excel"]
        };
        if (!newChange.schema._attributeGroups.some((item: any) => item.key == "Select Form")) {
          newChange.schema._attributeGroups = [formSelect, ...newChange.schema._attributeGroups];
        }
      }
    }
    return newChange;
  }


  //权限判断
  public roleJudgment(newChange: WorkPackageChangeset, isProjectAdmin: boolean) {
    if (!isProjectAdmin) {
      let pristineResource = newChange.pristineResource;
      if (pristineResource) {
        // 当前是否关闭状态
        let isClosed: boolean = false;
        // 是否存在分配角色中
        let isPersonnel: boolean = false;
        //表单类型
        let attributeGroups: any = pristineResource._attributeGroups;
        //分配者ID
        let assigneeID: string = '';
        //分配给ID
        let responsible: string = '';
        //创建者ID
        let author: string = '';

        if (pristineResource.assignee) {
          assigneeID = pristineResource.assignee.id;
        }

        if (pristineResource.responsible) {
          responsible = pristineResource.responsible.id;
        }

        if (pristineResource.author) {
          author = pristineResource.author.id;
          this.is_author = pristineResource.author.id
        }

        if (pristineResource.status) {
          isClosed = pristineResource.status.isClosed;
        }

        if (this.currentUser.userId == assigneeID || this.currentUser.userId == responsible) {
          isPersonnel = true;
        } else {
          isPersonnel = false;
        }
        Object.keys(newChange.schema).forEach((key: any) => {
          if (newChange.schema[key].hasOwnProperty('writable')) {
            
            let iscustomFieldGroup = this.customFieldGroup[newChange.schema[key].attributeGroup]
            
            let isWritable: boolean = false;
            if (this.workPackage.type.name == 'RISC' || this.workPackage.type.name ==  "RISC(inspection)" || this.workPackage.type.name == 'RISC(EM)') {
              if (iscustomFieldGroup) {
                isWritable = true;
              }
            } else {
              if (isPersonnel) {
                isWritable = true;
              }
            }

            attributeGroups.forEach((group: any) => {

              let isStatuses: boolean = false;

              if (group.statuses.length > 0) {
                isStatuses = group.statuses.some((item: any) => {
                  return (item.id == this.workPackage.status.id);
                })
              }
              let isPrincipals = group.principals.hasOwnProperty(
                'current_user_can_edit'
              );
              let isCurrentUserCanEdit: boolean = false;
              if (isPrincipals) {
                isCurrentUserCanEdit = group.principals.current_user_can_edit;
              }
              group.attributes.forEach((item: any) => {
                let keyName: string = key;
                if (keyName == 'startDate' || keyName == 'dueDate') {
                  keyName = 'date';
                }
                if (keyName == item) {
                  if (!isStatuses || this.workPackage.type.name == 'RISC'|| this.workPackage.type.name ==  "RISC(inspection)"  || this.workPackage.type.name == 'RISC(EM)') {
                    if (isClosed) {
                      isWritable = false;
                      console.log('关闭状态');
                    } else {
                      if (this.workPackage.type.name == 'RISC'|| this.workPackage.type.name ==  "RISC(inspection)"  || this.workPackage.type.name == 'RISC(EM)') {
                        if (iscustomFieldGroup) {
                          isWritable = true;
                        } else {
                          isWritable = false;
                          console.log('RISC：不是项目负责人或指派者,或作者');
                        }
                      } else {
                        if (isPrincipals) {
                          // isWritable = this.customFieldGroup[newChange.schema[key].attributeGroup]
                          if (isCurrentUserCanEdit) {
                            isWritable = true;
                          } else {
                            isWritable = false;
                            console.log('不是表单负责人');
                          }
                        } else {
                          // isWritable = this.customFieldGroup[newChange.schema[key].attributeGroup]
                          if (iscustomFieldGroup) {
                            isWritable = true;
                          } else {
                            isWritable = false;
                            console.log('不是项目负责人或指派者,或作者');
                          }
                        }
                      }
                    }
                  } else {
                    isWritable = false;
                    console.log('状态不可改');
                  }
                }
              });
            });
            if(this.authorRole == 'role1'&& this.authorJudge.includes(newChange.schema[key].name)){
                newChange.schema[key].writable = true;     
            }else{
              newChange.schema[key].writable = isWritable;
            }
          }
        });
      }
    }
  }
  setRefresh () {
    this.halEditing
    .typedState<WorkPackageResource, WorkPackageChangeset>(this.workPackage)
    .values$()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((change: WorkPackageChangeset) => {
      const newChange = this.manipulationData(change);
      if (this.workPackage.type.name !== this.oldType) {
        this.oldType = this.workPackage.type.name;
        if (this.workPackage.id == 'new') {
          this.fileRefNo = {};
          this.getFileRefNo(newChange);
        } else {
          this.fileRefNoInfo = {};
          this.getFileRefNoInfo(newChange);
        }
      }
      this.resourceContextChange.next(this.contextFrom(newChange));
    });
    const change = this.halEditing.changeFor<WorkPackageResource, WorkPackageChangeset>(this.workPackage);
    const newChange = this.manipulationData(change);
    this.get_custom_field_group_name(()=>{
      this.getFileRefNoInfo(newChange);
})
this.resourceContextChange.next(this.contextFrom(change));
  }
  public init() {
    // this.handleCheck.eventBus.emit('refreshPdf')
    this.hasPhotoLog()

    this.$element = jQuery(this.elementRef.nativeElement);
    const change = this.halEditing.changeFor<WorkPackageResource, WorkPackageChangeset>(this.workPackage);
    this.resourceContextChange.next(this.contextFrom(change));
    const newChange = this.manipulationData(change);

    this.oldType = this.workPackage.type.name;

    if (this.workPackage.id == 'new') {
      this.isRiscForm = true
      if (this.workPackage.type.name == 'RISC'|| this.workPackage.type.name ==  "RISC(inspection)"  || this.workPackage.type.name == 'RISC(EM)') {

        this.workPackage.subject = 'RISC'
      }
      this.fileRefNo = {};
      this.getFileRefNo(newChange);
    } else {
      if (this.workPackage.type.name == 'RISC' || this.workPackage.type.name ==  "RISC(inspection)"  || this.workPackage.type.name == 'RISC(EM)') {
        this.isRiscForm = this.workPackage.isRiscForm
      } else {
        this.isRiscForm = true
      }
            this.fileRefNoInfo = {};

      this.get_custom_field_group_name(()=>{
              this.getFileRefNoInfo(newChange);
      })
    }

    if (change.minimalPayload.lockVersion) {
      this.lockVersion = change.minimalPayload.lockVersion || 0;
    }


    this.siteDiaryTitle = this.workPackage.subject;

    // Whenever the resource context changes in any way,
    // update the visible fields.
    this.resourceContextChange
      .pipe(
        takeUntil(componentDestroyed(this)),
        distinctUntilChanged<ResourceContextChange>((a, b) => _.isEqual(a, b)),
        map(() => this.halEditing.changeFor(this.workPackage))
      )
      .subscribe((change: WorkPackageChangeset) => {
        const newChange = this.manipulationData(change);
        if (this.workPackage.type.name !== this.oldType) {
          this.oldType = this.workPackage.type.name;
          if (this.workPackage.id == 'new') {
            this.fileRefNo = {};
            this.getFileRefNo(newChange);
          } else {
            this.fileRefNoInfo = {};
            this.getFileRefNoInfo(newChange);
          }
        }
      });

    if ((this.workPackage.type.name == 'RISC' ||  this.workPackage.type.name ==  "RISC(inspection)"  || this.workPackage.type.name == 'RISC(EM)') && this.workPackage.id != 'new') {
      
      const currentUser: any = document.querySelector('meta[name=current_user]');

      this.workPackageriscid = `${window.location.origin}/api/v3/work_packages/${this.workPackage.id}/export`
      this.riscpdfdowint = true

      this.http.get(`/api/v3/users/${currentUser.dataset.id}/groups`).subscribe((userzhu: any) => {

        this.signuserzhu = userzhu
      })

      //risc
      this.http.get(`/api/v3/work_packages/${this.workPackage.id}/custom_fields/sign_custom_fields`).subscribe((signuser: any) => {

        this.signusername = signuser
      })

      this.pdfSrc = `${window.location.origin}/api/v3/work_packages/${this.workPackage.id}/export?${Math.random()}`

    } else {
      // let pdf = require(`../../../../assets/pdf/risc.pdf`)
      // const that = this
      // var xhr = new XMLHttpRequest();
      // xhr.open('GET', `pdf/risc.pdf?${Math.random()}`, true);
      // xhr.responseType = 'arraybuffer';

      // xhr.onload = function (e) {
      //   if (this.status == 200) {
      //     // get binary data as a response
      //     console.log(this.response)
      //     that.pdfupdatestate(this.response)
      //   }
      // };

      // xhr.send();
      // this.pdfSrc = `${window.location.origin}/api/v3/work_packages/risc_empty_template`

    }


    // Update the resource context on every update to the temporary resource.
    // This allows detecting a changed type value in a new work package.
    this.halEditing
      .typedState<WorkPackageResource, WorkPackageChangeset>(this.workPackage)
      .values$()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((change: WorkPackageChangeset) => {
        const newChange = this.manipulationData(change);
        if (this.workPackage.type.name !== this.oldType) {
          this.oldType = this.workPackage.type.name;
          if (this.workPackage.id == 'new') {
            this.fileRefNo = {};
            this.getFileRefNo(newChange);
          } else {
            this.fileRefNoInfo = {};
            this.getFileRefNoInfo(newChange);
          }
        }
        this.resourceContextChange.next(this.contextFrom(newChange));
      });

    // 签名risc
  }
  public ngOnInit() {
    console.log('change')
   
    this.hasPhotoLog()
    this.handleCheck.eventBus.subscribe( (msg:any) => {
      if(msg == 'overViewTab') {
        this.groupedFields = []
        this.init()
      } else if (msg == 'refresh') {
        this.setRefresh()
      } else if(msg == 'workpackgeChange') {
        let workpackge = true
        this.halEditing
        .typedState<WorkPackageResource, WorkPackageChangeset>(this.workPackage)
        .values$()
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((change: WorkPackageChangeset) => {
          const newChange = this.manipulationData(change);
   
            this.oldType = this.workPackage.type.name;
            if (this.workPackage.id == 'new') {
       
              this.getFileRefNo(newChange, workpackge);
            } else {
           
              this.getFileRefNoInfo(newChange,workpackge);
            }
          
          this.resourceContextChange.next(this.contextFrom(newChange));
        });
      }else if (msg == 'showDialog') {
        this.assignNext('')
      }
    })
    this.$element = jQuery(this.elementRef.nativeElement);
    const change = this.halEditing.changeFor<WorkPackageResource, WorkPackageChangeset>(this.workPackage);
    this.resourceContextChange.next(this.contextFrom(change));
    const newChange = this.manipulationData(change);

    this.oldType = this.workPackage.type.name;
    if (this.workPackage.id == 'new') {
      this.isRiscForm = true
      if (this.workPackage.type.name == 'RISC'|| this.workPackage.type.name ==  "RISC(inspection)"  || this.workPackage.type.name == 'RISC(EM)') {

        this.workPackage.subject = 'RISC'
      }
      this.fileRefNo = {};
      this.getFileRefNo(newChange);
    } else {
      if (this.workPackage.type.name == 'RISC'|| this.workPackage.type.name ==  "RISC(inspection)"  || this.workPackage.type.name == 'RISC(EM)') {
        this.isRiscForm = this.workPackage.isRiscForm
      } else {
        this.isRiscForm = true
      }
            this.fileRefNoInfo = {};

      this.get_custom_field_group_name(()=>{
              this.getFileRefNoInfo(newChange);
      })
    }

    if (change.minimalPayload.lockVersion) {
      this.lockVersion = change.minimalPayload.lockVersion || 0;
    }


    this.siteDiaryTitle = this.workPackage.subject;

    // Whenever the resource context changes in any way,
    // update the visible fields.
    this.resourceContextChange
      .pipe(
        takeUntil(componentDestroyed(this)),
        distinctUntilChanged<ResourceContextChange>((a, b) => _.isEqual(a, b)),
        map(() => this.halEditing.changeFor(this.workPackage))
      )
      .subscribe((change: WorkPackageChangeset) => {
        const newChange = this.manipulationData(change);
        if (this.workPackage.type.name !== this.oldType) {
          this.oldType = this.workPackage.type.name;
          if (this.workPackage.id == 'new') {
            this.fileRefNo = {};
            this.getFileRefNo(newChange);
          } else {
            this.fileRefNoInfo = {};
            this.getFileRefNoInfo(newChange);
          }
        }
      });

    if ((this.workPackage.type.name == 'RISC' || this.workPackage.type.name ==  "RISC(inspection)"  || this.workPackage.type.name == 'RISC(EM)')&& this.workPackage.id != 'new') {
      
      const currentUser: any = document.querySelector('meta[name=current_user]');

      this.workPackageriscid = `${window.location.origin}/api/v3/work_packages/${this.workPackage.id}/export`
      this.riscpdfdowint = true

      this.http.get(`/api/v3/users/${currentUser.dataset.id}/groups`).subscribe((userzhu: any) => {

        this.signuserzhu = userzhu
      })

      //risc
      this.http.get(`/api/v3/work_packages/${this.workPackage.id}/custom_fields/sign_custom_fields`).subscribe((signuser: any) => {

        this.signusername = signuser
      })

      this.pdfSrc = `${window.location.origin}/api/v3/work_packages/${this.workPackage.id}/export?${Math.random()}`

    } else {
      // let pdf = require(`../../../../assets/pdf/risc.pdf`)
      // const that = this
      // var xhr = new XMLHttpRequest();
      // xhr.open('GET', `pdf/risc.pdf?${Math.random()}`, true);
      // xhr.responseType = 'arraybuffer';

      // xhr.onload = function (e) {
      //   if (this.status == 200) {
      //     // get binary data as a response
      //     console.log(this.response)
      //     that.pdfupdatestate(this.response)
      //   }
      // };

      // xhr.send();
      // this.pdfSrc = `${window.location.origin}/api/v3/work_packages/risc_empty_template`

    }


    // Update the resource context on every update to the temporary resource.
    // This allows detecting a changed type value in a new work package.
    this.halEditing
      .typedState<WorkPackageResource, WorkPackageChangeset>(this.workPackage)
      .values$()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((change: WorkPackageChangeset) => {
        const newChange = this.manipulationData(change);
        if (this.workPackage.type.name !== this.oldType) {
          this.oldType = this.workPackage.type.name;
          if (this.workPackage.id == 'new') {
            this.fileRefNo = {};
            this.getFileRefNo(newChange);
          } else {
            this.fileRefNoInfo = {};
            this.getFileRefNoInfo(newChange);
          }
        }
        this.resourceContextChange.next(this.contextFrom(newChange));
      });

    // 签名risc

  if(this.currentUser.userId == this.workPackage.author.id && this.workPackage.status.name == 'Submitted') {
      this.authorRole = 'role1'
      this.workPackage.authorRole = 'role1'
    }
  }

  pdfupdatestate(data: ArrayBuffer) {
    this.pdfSrc = data
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    // Nothing to do
  }

  getMapData(){
    
  }


  // --------------------------------表格控件--------------------------------

  //表格控件的问题，当导入新的表格时会把最初生成表格的参数抹掉
  ngAfterViewInit() {
    // const loader = new Loader({
    //   apiKey: "AIzaSyBqOcepLBMdP3gQHkFG4xXYgS088EuCEC4",
    //   version: "weekly",
    // });
    
    // let map
    // loader.load().then(() => {
    //   map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
    //     center: { lat: -34.397, lng: 150.644 },
    //     zoom: 8,
    //   });
    //    // The marker, positioned at Uluru
    //    const marker = new google.maps.Marker({
    //     position:  { lat: -34.397, lng: 150.644 },
    //     map: map,
    //   });
    // });
     
    
    if (this.workPackage.id == 'new') {
      if (this.workPackage.type.name == 'RISC'|| this.workPackage.type.name ==  "RISC(inspection)"  || this.workPackage.type.name == 'RISC(EM)') {
        setTimeout(() => {
          jQuery('#wp-new-inline-edit--field-subject').attr('disabled', 'disabled')
          const that = this
          var xhr = new XMLHttpRequest();
          xhr.open('GET', `pdf/risc.pdf`, true);
          xhr.responseType = 'arraybuffer';

          xhr.onload = function (e) {
            if (this.status == 200) {
              // get binary data as a response
              that.pdfupdatestate(this.response)
            }
          };

          xhr.send();
        }, 500)
      }
    }
    let LicenseKey
    if (window.location.host == "103.82.213.202") {
      LicenseKey = '103.82.213.202,E331992477277567#B0AnyU3CdJ3SRTVjZzsiQjRWelNmYOFlMmxGVTNnUwUkcyFzLOFnZqdlRBhUWyYlWxMGZ7MjQllnerFnaEBVRGd4cy2SeRhnWHNVYjV5crNlWmp7YXtmdkpVeCJXRxYTYZFlb796aP3CbZxUZGV7bZpXUxd5ZDx6MG3UcthjYvM6UKlmVolDMyZjNzM5bJdzT7MzLStUc8JDWlRHdoVjUaJnVj56TahkY8czQWRXNpRlaRtWZl9WNWdTck3UNTZTYOJUTVRVY9wkSJhUbWdUVHlGSPdnUphWQ4E6LWBDOW3CcYV7cpZ6ZMdXS4EETEVUNuR6UhFkaGJiOiMlIsICNxgDOyQTMiojIIJCL9gTN4kTO8ADO0IicfJye&Qf35VfiU5TzYjI0IyQiwiIzEjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsICOxAzMxADIyEjNwAjMwIjI0ICdyNkIsIiNxcDMxIDMyIiOiAHeFJCLiIDMy8yMxIjLygjLzATMiojIz5GRiwiIPdlTVh4QiojIh94QiwSZ5JHd0ICb6VkIsIyN6UzN7IzN7QjM9kTMzMjI0ICZJJCL3V6csFmZ0IiczRmI1pjIs9WQisnOiQkIsISP3EkSQRTZ9EldndUS624dPN5ZYFGd6lzVul5QmFVSypWOYdWWFlHMuF7MqhDSHdzNYR7N6x4L9kVS796Rrp4Y4pEcnRmYM36Sq9kNXlzSwY4S4kXboFWRX9WdjZDO0Alb5'
      console.log('103.82.213.202,');
    } else if (window.location.host == "mobility-uat.chunwo.com") {
      console.log('mobility-uat.chunwo.com');
      LicenseKey = 'mobility-uat.chunwo.com,E331992477277567#B0fVelDVjh5RpJUSxhnaxBDZxoWZj3mbXJzVC3Cap3mbVtya8M4QHdlNvpmZQFUaPJ5V4skS4IlbtdWO9ZTcppGbvYFcCFlaFtCS9lXVqZWTIx4N5EHVKNzNGhkVQtEbMJzbr2ya9ZTW6dTY5RTOitGZChmW6VlaCRnYvYDVpZ5R82ib6FFWHdjNzY6TmBjUzF6biVzTxZUZ0l5a6MTOpR4UURkUNVmM8MmWxsiVpZmd7NEOLNmTPhmQOx4Q7RWOEREbhhEbmV5MNNUQStWaxMmcaVjQlpmNCBTTp3UOwl5UxlGWkh7MGZHSxRHS7dHcHh5aplzTUJ6NYRlI0IyUiwiIyEDM4UjN5IjI0ICSiwCN5cjM6QDMycTM0IicfJye35XX3JSVPNjNiojIDJCLiMTMuYHITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI8AzM4ETMgUTM7ADMyAjMiojI4J7QiwiI6EzNwEjMwIjI0ICc8VkIsISbvNmLvdnb5h6YuQXY55Se4lGbpJ6btJiOiMXbEJCLi24VOVFSDJiOiEmTDJCLlVnc4pjIsZXRiwiI7YTN7cjM7cDNykTOxMzMiojIklkIs4XZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zdyVlTUN7MLV7QqxUM886L7RFVYdlbvEDWhJXW5UFTaFDdvdnWJhmbJ3CNld4Uydla4MXMw3GM4hjeE3CU5U6bkxEUvhXYwUENygUWGlld9d4ZqZ7Q8c4SMFwU55'
    } else {
      console.log('Chun Wo Construction & Engineering Co Ltd,mobility.chunwo.com,');
      LicenseKey = 'Chun Wo Construction & Engineering Co Ltd,mobility.chunwo.com,433369283719154#B0s08Q9ETcVhDbnhXQk9WR5hVOLB7cvM7SC3WZalUZ9QETQtCcVVmQ4xEZTZFO7MnYHRTUkh6dthHMM3CN6MlYm9GWyUldxtUUX3SV8oUZtdVZrd5KhljRyMVS8YUVmZTZ4JkbqVGZ8EUbxQ5KH5mYyMjU73UdzgDcCdnbEVzR6FXNmpVWGRnVKBVZvlmRtdVVUNDMWNXOwYESu5WRChXaHJzRPZlZ8oGMFx4ZIpHdplkUvUDbL9kdsFzKWJTN7JnavkWSll6blRUQrMEVk5GWyZ5MC3mN6VTQCNjZilzZxZXVjpUM5gEVY5mapdHevw4UENHdrFzZBRXRyBXZiojITJCLiEzQwUTN8EkI0ICSiwCMxIjMxUDO9QTM0IicfJye#4Xfd5nIV34M6IiOiMkIsIyMx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiQjMxUjMwASMxYDMwIDMyIiOiQncDJCLi46bj9yb79WdoNmL9RXaslmYv5mI0IyctRkIsICZ4xEIvNEIn9WayVWZul6ZuVEImAibvlGdjVnc4NnbvNEIvdFIuVHaDJiOiEmTDJCLiQTNxkTM7MDOykjNzMzM4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnhVM4Z6YDh7Uo3UVz8UTHpVYEFVYvsSWUFWONdGOz9kS7UUSz9mUtlEOiRVNKRUcotyYUxkc8wUeHF4TYBnRaRUMWtkUQhWetRnQZVTe5kmaPRFd8oVVuNqe'
    }
    GC.Spread.Sheets.LicenseKey = (GC.Spread.Excel.IO as any).LicenseKey = LicenseKey

    this.spread = new GC.Spread.Sheets.Workbook(document.getElementById("ss"), { calcOnDemand: true });
    this.excelIo = new GC.Spread.Excel.IO();
    this.spread.options.tabStripVisible = false;//去掉整个sheetl操作栏
    if (!this.workPackage.workPackageExcel) return
    this.inputExcel()
  }
  isbooleam(isbolem: boolean) {
    this.isexcel = isbolem
  }

  inputExcel() {
    var thart = this;

    const { workPackageExcel: { index, doc_id } } = thart.workPackage
    thart.excel_id = index ? index : 0
    thart.getBlob(`${window.location.origin}/api/v3/logical_docs/doc/${doc_id}/stream`, index)
    thart.sheet = thart.spread.getActiveSheet();
    thart.textprointpo()
    var spreadNS = GC.Spread.Sheets;
    //监听修改
    thart.spread.bind(spreadNS.Events.EditEnded, function (e: any, args: any) {
      thart.isbooleam(true)
    });
  }

  //去掉表格零值设置
  textprointpo() {
    var oldPaint = GC.Spread.Sheets.CellTypes.Text.prototype.paint;
    GC.Spread.Sheets.CellTypes.Text.prototype.paint = function (ctx: any, value: any, x: any, y: any, w: any, h: any, style: any, context: any) {
      if (value === 0) {
        oldPaint.call(this, ctx, "", x, y, w, h, style, context);
      }
      else {
        oldPaint.apply(this, arguments);
      }
    }
  }

  savePDF() {
    const that = this
    const name = that.workPackage.name
    this.spread.savePDF(function (blob: any) {
      saveAs(blob, name);
    }, console.log);
  }

  signPDF() {
    this.pdfShow = true
    this.loadingShow = true
    jQuery('#top-menu').css('z-index', 20);

    const save_btn: any = {
      type: "custom",
      id: "save_btn",
      title: this.I18n.t("js.documents.save_download"),
      onPress: () => {
        this.loadingShow = true
        this.cdRef.detectChanges();
        this.instance.exportPDF().then((buffer: any) => {
          let name = this.workPackage.workPackageExcel.doc_name.replace('xlsx', 'pdf')
          console.log(name)
          const files = new File([buffer], name, { type: "application/pdf" });
          const blob = new Blob([buffer], { type: "application/pdf" });
          const file = new FormData();
          file.append("file", files)
          if (typeof this.workPackage.pdfDocId == 'number') {
            this.service.uploadPdf({
              id: this.workPackage.pdfDocId,
              file
            }).subscribe((res: any) => {
              if (res.result === 'ok') {
                this.pdfPut(blob, name)
                this.closeMask()
                this.colseToast()
                this.cdRef.detectChanges();
              }
            }, error => {
              this.loadingShow = false
              this.cdRef.detectChanges();
            })
          } else {
            this.service.savePdf({
              id: this.workPackage.id,
              file
            }).subscribe((res: any) => {
              if (res.result === 'ok') {
                this.pdfPut(blob, name)
                this.closeMask()
                this.colseToast()
                window.location.reload();
                this.cdRef.detectChanges();
              }
            }, error => {
              this.loadingShow = false
              this.cdRef.detectChanges();
            })
          }
        })
      }
    };

    const save: any = {
      type: "custom",
      id: "save",
      title: this.I18n.t("js.documents.save"),
      onPress: () => {
        this.loadingShow = true
        this.cdRef.detectChanges();
        this.instance.exportPDF().then((buffer: any) => {

          console.log(this.workPackage.pdfDocId)
          let name = this.workPackage.workPackageExcel.doc_name.replace('xlsx', 'pdf')
          const files = new File([buffer], name, { type: "application/pdf" });
          const file = new FormData();
          file.append("file", files)
          if (typeof this.workPackage.pdfDocId == 'number') {
            this.service.uploadPdf({
              id: this.workPackage.pdfDocId,
              file
            }).subscribe((res: any) => {
              this.closeMask()
              this.colseToast()
              this.cdRef.detectChanges();
            }, error => {
              this.loadingShow = false
              this.cdRef.detectChanges();
            })
          } else {
            this.service.savePdf({
              id: this.workPackage.id,
              file
            }).subscribe((res: any) => {
              this.closeMask()
              this.colseToast()
              window.location.reload();
              this.cdRef.detectChanges();
            }, error => {
              this.loadingShow = false
              this.cdRef.detectChanges();
            })
          }
        })
      }
    };

    let pdfUrl: any
    if (typeof this.workPackage.pdfDocId == 'number') {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', `${location.protocol}//${location.host}/api/v3/logical_docs/doc/${this.workPackage.pdfDocId}/pdf`)
      xhr.responseType = 'arraybuffer'
      xhr.onload = () => {
        pdfUrl = xhr.response
        // this.psPdfKit(pdfUrl,save,save_btn)
      }
      xhr.send()
    } else {
      let that = this
      this.spread.savePDF((blob: any) => {
        var reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        reader.onload = function (res) {
          pdfUrl = this.result
          // that.psPdfKit(pdfUrl,save,save_btn)
        }
      })
    }
  }
  colseToast() {
    this.toastShow = true;
    this.loadingShow = false
    setTimeout(() => {
      this.toastShow = false;
      this.cdRef.detectChanges();
    }, 2000);
    this.cdRef.detectChanges();
  }
  //关闭遮罩层
  closeMask() {
    this.pdfShow = false
    this.cdRef.detectChanges();
    jQuery('#top-menu').css('z-index', 22);
  }

  // psPdfKit(pdfUrl:any,save:any,save_btn:any){
  //   PSPDFKit.load({
  //     baseUrl: location.protocol + "//" + location.host + "/assets/frontend/assets/",
  //     document: pdfUrl,
  //     container: '#app',
  //     styleSheets: [`${location.protocol}//${location.host}/pdfkit/pdfkit.css`],
  //     disableWebAssemblyStreaming: true,
  //     licenseKey: 'yGsvyVYBPyOEmvBXe6Uje3jGUPoqUvrM-2aKa9iKZivlvy2Vf52NFYMyfqvVTlpDxPir9o34j9gwsnnURAFNCkVZFnhs5R5dvh8yg5TFMYIRBo-030bVlKcASCeDlQojh6-hgKvIynPtelqR1bOhz-o--US0YYyBaCABiGW2kPfjxn0Cv5xBuMxCqCbo63Xzqsa3jS8LCX2_gzZlBH0vjaNYPvJzLOMlRqV2HE7WMKpGdTexs5DLdEnub8lLfzEgEFUpSEKBDoIjXaFMrUmUC-fR7JLdpEbvw1WNrPZ6P-rjdEyCVG_V7EHmPq6P0h27FPEDtuBvycVUUVYG4EnpkIKIRPgztu61fs1LXc7VjAN1YaHs-4p1rL1iFgst5SuK8EfwwormhbgXqkzcpcYbOtMoWciCUKNlqdrivhfen6OI7gGoMxTcGvgnX0hmWWBv',
  //   }).then(async instance => {
  //     this.loadingShow = false
  //     this.cdRef.detectChanges();
  //     instance.setViewState(viewState =>
  //       viewState.set("interactionMode", PSPDFKit.InteractionMode.INK_SIGNATURE)
  //     );
  //     instance.setToolbarItems(items=>{
  //       items.push(save)
  //       items.push(save_btn)
  //       return items
  //     })
  //     this.instance = instance
  //   });
  // }

  pdfPut(blob: any, name: any) {
    const objectUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = objectUrl;
    a.target = "_blank";
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(objectUrl);
    document.body.removeChild(a);
  }

  refreshmark() {
    const csrf_token: string | undefined = jQuery("meta[name=csrf-token]").attr("content");
  let requestHeader: any = {
    'X-Authentication-Scheme': 'Session',
    'X-Requested-With': 'XMLHttpRequest'
  };
    if (csrf_token) {
      requestHeader["X-CSRF-TOKEN"] = csrf_token;
    }
    console.log(requestHeader)
    this.http.post(
      ` /api/v3/work_packages/${this.workPackage.id}/watermark/watermark_update`,
      {},
      {
        withCredentials: true,
        headers: requestHeader
      }
    ).subscribe((res: any) => {
      jQuery(".showwatermark").css("display","block")
      setTimeout(() => {
        this.hidewatermark()
      }, 2500);
    })

  }
  hidewatermark(){
    jQuery(".showwatermark").css("display","none")
    window.location.reload()
  }

  //注册字体
  registerServerFont(name: any, type: any, serverPath: any) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', serverPath, true);
    xhr.responseType = 'blob';

    xhr.onload = function (e) {
      if (this.status == 200) {
        // get binary data as a response
        var blob = this.response;

        //将Blob 对象转换成 ArrayBuffer
        var reader = new FileReader();
        reader.onload = function (e) {
          var fontrrayBuffer = reader.result;

          var fonts = GC.Spread.Sheets.PDF.PDFFontsManager.getFont(name) || {};
          fonts[type] = fontrrayBuffer;
          GC.Spread.Sheets.PDF.PDFFontsManager.registerFont(name, fonts);

        }
        reader.readAsArrayBuffer(blob);
      }
    };

    xhr.send();
  }


  //表格设置
  spreadjsSetUp() {
    if (this.excel_id == 0) {
      this.sheet.options.isProtected = true;//开启表单保护
      const option = this.sheet.options.protectionOptions;//限制行为方法
      option.allowSelectLockedCells = false//不允许用户选择单元格
    }
    this.spread.options.scrollbarMaxAlign = true;//滚动条对齐视图中表单的最后一行或一列
    this.spread.options.scrollbarShowMax = true;//基于表单全部的行列总数显示滚动条
    this.spread.options.newTabVisible = false;//去掉表格下面的sheet按钮，不给添加子表单\
    this.spread.options.tabStripVisible = false;//去掉整个sheetl操作栏
    var printInfo = this.sheet.printInfo();
    printInfo.showBorder(true);
    printInfo.showGridLine(false);
    printInfo.showColumnHeader(GC.Spread.Sheets.Print.PrintVisibilityType.hide);
    printInfo.showRowHeader(GC.Spread.Sheets.Print.PrintVisibilityType.hide);
    printInfo.centering(GC.Spread.Sheets.Print.PrintCentering.horizontal);

  }

  //插入表格
  getBlob(url: string, excel_id: number) {
    let excl = new GC.Spread.Excel.IO();
    var xhr = new XMLHttpRequest()
    const that = this
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      that.excelIo.open(xhr.response, (json: any) => {
        this.spread.fromJSON(json);
        this.spread.setActiveSheetIndex(excel_id)
        that.sheet = that.spread.getActiveSheet()
        if (excel_id == 0) {
          for (let index = 0; index < this.spread.sheets.length; index++) {
            if (index != excel_id) {
              this.spread.sheets[index].visible(false)
            }
          }
        }
        this.registerServerFont('新細明體', 'normal', `${window.location.origin}/fonts/R-PMingLiU-TW-2.ttf`);
        this.spreadjsSetUp()
      }, (err: any) => {
        console.log(err)
      });
    }
    xhr.send()
  }

  import() {
    let file = document.getElementById('file') as any;

    file = file.files[0];
    this.excelIo.open(file, (json: any) => {
      let workbookObj = json;
      this.spread.fromJSON(workbookObj);
    }, (err: any) => {
      console.log(err)
    });
  }


  cloneexp() {
    this.isbooleam(false)
    this.inputExcel()
  }


  //导出表格
  export() {
    this.isbooleam(false)
    const { workPackageExcel } = this.workPackage
    const { doc_id, doc_name } = workPackageExcel
    for (let index = 0; index < this.spread.sheets.length; index++) {
      if (index != this.excel_id) {
        this.spread.sheets[index].visible(true)
      }
    }
    this.spread.setActiveSheetIndex()
    let json: any = this.spread.toJSON();
    const that = this
    this.excelIo.save(json, function (blob: any) {
      var files = new File([blob], doc_name, { type: blob.type })
      let file = new FormData();
      file.append('file', files);

      const csrf_token: string | undefined = jQuery("meta[name=csrf-token]").attr("content");
      let newHeaders: any = {};
      newHeaders["X-Authentication-Scheme"] = "Session";
      newHeaders["X-Requested-With"] = "XMLHttpRequest"
      if (csrf_token) {
        newHeaders["X-CSRF-TOKEN"] = csrf_token;
      }
      that.http.post(
        `api/v3/logical_docs/doc/${doc_id}/upload`,
        file,
        {
          withCredentials: true,
          headers: newHeaders
        }
      )
        .subscribe(res => {
          that.isexcel = false
          for (let index = 0; index < that.spread.sheets.length; index++) {
            if (index != that.excel_id) {
              that.spread.sheets[index].visible(false)
            }
          }
        });
    }, function (err: any) {
      console.log(err)
    })


  }

  //----------------------------------------------------------------------------


  public postForm(key?: string, text?: string) {
    let payload: any = {};
    key && (payload[key] = text)

    const id = this.workPackage.id;
    const csrf_token: string | undefined = jQuery("meta[name=csrf-token]").attr("content");
    let newHeaders: any = {};
    newHeaders["X-Authentication-Scheme"] = "Session";
    newHeaders["X-Requested-With"] = "XMLHttpRequest";

    if (csrf_token) {
      newHeaders["X-CSRF-TOKEN"] = csrf_token;
    }
    this.http
      .patch(
        `/api/v3/work_packages/${id}`,
        {
          lockVersion: this.lockVersion,
          _links: {},
          table: {
            payload,
            schema: this.tableSchema
          }
        },
        {
          withCredentials: true,
          headers: newHeaders
        }
      )
      .subscribe(res => {
        // console.log("activitiesData", res);
        let obj: any = res;
        this.workPackage.table = obj.table;
        this.wpCacheService.updateWorkPackage(this.workPackage);
        // console.log(obj);
      });
  }

  public siteDiaryDblclick(index: string | number, name?: any) {
    if (name) {
      this.siteDiaryTable[index].isChangePlant = true;
      this.siteDiaryTable[index].tempCode = this.siteDiaryTable[index].code;
    } else {
      this.siteDiaryTable[index].isChange = true;
      this.siteDiaryTable[index].tempText = this.siteDiaryTable[index].text;
    }
  }

  public siteDiaryBlur(index: string | number, name?: any) {
    if (name) {
      this.siteDiaryTable[index].isChangePlant = false;
      this.tableSchema[this.siteDiaryTable[index].key].code = this.siteDiaryTable[index].code;
      this.postForm();
    } else {
      this.siteDiaryTable[index].isChange = false;
      this.postForm(this.siteDiaryTable[index].key, this.siteDiaryTable[index].text);
    }
  }

  public siteDiaryCancel(index: string | number, name?: any) {
    if (name) {
      this.siteDiaryTable[index].isChangePlant = false;
      this.siteDiaryTable[index].code = this.siteDiaryTable[index].tempCode;
    } else {
      this.siteDiaryTable[index].isChange = false;
      this.siteDiaryTable[index].text = this.siteDiaryTable[index].tempText;
    }
  }

  private refresh(change: WorkPackageChangeset) {
    // Prepare the fields that are required always
    const isNew = this.workPackage.isNew;
    const resource = change.projectedResource;
    const state = this.workPackage.status.name
    const NewnotBeenptyList = [
    //  'Document Reference',
    //  '(1) Location of Work',
    //  '(2) Work to be inspected/surveyed',
    //  '(2) Date & Time',
     '(3) Work Proposed after Approval of (2)',
    ]
    if (!resource.project) {
      this.projectContext = { matches: false, href: null };
    } else {
      this.projectContext = {
        href: this.PathHelper.projectWorkPackagePath(resource.project.idFromLink, this.workPackage.id!),
        matches: resource.project.href === this.currentProject.apiv3Path
      };
    }

    if (isNew && !this.currentProject.inProjectContext) {
      this.projectContext.field = this.getFields(resource, ["project"]);
    }
    let attributeGroups: any = [];
    if (isNew) {
      attributeGroups = resource.schema._attributeGroups;
    } else {
      attributeGroups = change.pristineResource._attributeGroups;
    }

    let obj: any = {};

    let peon = attributeGroups.reduce((cur: any[], next: { name: string | number }) => {
      obj[next.name] ? "" : (obj[next.name] = true && cur.push(next));
      return cur;
    }, []);
    // 合并数据
    this.groupedFields = this.rebuildGroupedFields(resource, peon);
    console.log(this.groupedFields,'this.groupedFields');
    
    if(state == 'New') {
      let field = this.groupedFields.find( (item) => {
        return item.name == 'FOR Contractor: Submission detail'
      })
    if(field) {

      this.canAssigntNext = field!.members.every( (item:any):any => {
         if(NewnotBeenptyList.includes(item.label)){
          return item.field!.value !== null && item.field!.value !== ''
         } else {
           return true
         }
       })
    }
    } else if(state == "Inspecting") {
      let field = this.groupedFields.find( (item) => {
        return item.name == 'Inspection detail'
      })
      this.canAssigntNext = field!.members.some( (item) => {
        return item.field!.value === true
      })
    }
    // this.realyFields = this.rebuildGroupedFields(resource, peon);
    //剔除不需要的数据
    // this.groupedFields.splice(
    //   this.groupedFields.findIndex(item => item.name === "Other"),
    //   1
    // );
    //设置数组只有REPLY MESSAGE板块
    if(this.workPackage.type.name == 'Task'){
      this.realyFields = this.groupedFields.filter(item => item.name === "REPLY MESSAGE"),
      //去除REPLY MESSAGE
      this.groupedFields.splice(
        
        this.groupedFields.findIndex(item => item.name === "REPLY MESSAGE"),
        1
      );
    }
    //risc
    if ((this.workPackage.type.name == 'RISC' || this.workPackage.type.name ==  "RISC(inspection)"  || this.workPackage.type.name == 'RISC(EM)')&& this.workPackage.id != 'new') {
      this.http.get(`/api/v3/work_packages/${this.workPackage.id}`).subscribe((worlpageres: any) => {
        console.log('risc在这里开始的，进来了没you',this.groupedFields)
        this.groupedFields.find((groupeddata: any) => {
          groupeddata.members.find((res: any) => {
            if(res.label == "Works Category"){
              let name = '.' + res.name
               jQuery(name).css({'pointer-events':'none','background':'#eee'})
            }
            if(res.field) {
              if (res.field.displayName.indexOf('Signature') >= 0) {
                if (worlpageres[res.field.name]) {
                  this.groupimgdata[res.field.name] = `${window.location.origin}/api/v3/logical_docs/doc/${worlpageres[res.field.name]}/stream`
                }
              }
            }
          })
        })
      })
    }
    this.groupedFields.forEach((item:any)=>{
        item.members = item.members.filter((item:any)=>{
          return !this.menuArr.includes(item.label)
        })
    })
    console.log(this.groupedFields,'this.groupedFields');
    
    this.cdRef.detectChanges();
  }

  /**
   * Returns whether a group should be hidden due to being empty
   * (e.g., consists only of CFs and none of them are active in this project.
   */
  public shouldHideGroup(group: GroupDescriptor) {
    // Hide if the group is empty
    const isEmpty = group.members.length === 0;

    // Is a query in a new screen
    const queryInNew = this.workPackage.isNew && !!group.query;

    return isEmpty || queryInNew;
  }

  /**
   * angular 2 doesn't support track by property any more but requires a custom function
   * https://github.com/angular/angular/issues/12969
   * @param index
   * @param elem
   */
  public trackByName(_index: number, elem: { name: string }) {
    return elem.name;
  }

  public attributeGroupComponent(group: GroupDescriptor) {
    // we take the last registered group component which means that
    // plugins will have their say if they register for it.
    return this.hook.call("attributeGroupComponent", group, this.workPackage).pop() || null;
  }

  public attachmentListComponent() {
    // we take the last registered group component which means that
    // plugins will have their say if they register for it.
    return this.hook.call("workPackageAttachmentListComponent", this.workPackage).pop() || null;
  }

  public attachmentUploadComponent() {
    // we take the last registered group component which means that
    // plugins will have their say if they register for it.
    return this.hook.call("workPackageAttachmentUploadComponent", this.workPackage).pop() || null;
  }

  /*
   * Returns the work package label
   */
  public get idLabel() {
    return `#${this.workPackage.id}`;
  }

  public get projectContextText(): string {
    let id = this.workPackage.project.idFromLink;
    let projectPath = this.PathHelper.projectPath(id);
    let project = `<a href="${projectPath}">${this.workPackage.project.name}<a>`;
    return this.I18n.t("js.project.work_package_belongs_to", { projectname: project });
  }

  /*
   * Show two column layout for new WP per default, but disable in MS Edge (#29941)
   */
  public get enableTwoColumnLayout() {
    return this.workPackage.isNew && !this.browserDetector.isEdge;
  }

  /**
   *
   * @param attributeGroups
   * @returns {any}
   */
  private rebuildGroupedFields(resource: WorkPackageResource, attributeGroups: any) {
    if (!attributeGroups) {
      return [];
    }

    return attributeGroups.map((group: any) => {
      let groupId = this.getAttributesGroupId(group);
      let principal_name: string = '';
      if (group.principals) {
        if (group.principals.hasOwnProperty('principal_name') ) {
          principal_name = group.principals.principal_name
        } 
      }
      if (group._type === "WorkPackageFormAttributeGroup") {

        return {
          name: group.name,
          id: groupId || randomString(16),
          members: this.getFields(resource, group.attributes),
          type: group._type,
          isolated: false,
          noTitle: group.noTitle,
          principal_name:principal_name,
          sign:group.principals,
          isSub: group.isSub,
          status:group.statuses,
          real_principal_name: group.principals.real_principal_name
        };
      } else {
        return {
          name: group.name,
          id: groupId || randomString(16),
          query: this.halResourceService.createHalResourceOfClass(QueryResource, group._embedded.query),
          relationType: group.relationType,
          members: [group._embedded.query],
          type: group._type,
          principal_name:principal_name,
          sign:group.principals,
          isolated: true,
          status:group.statuses,
          real_principal_name: group.principals.real_principal_name
        };
      }
    });
  }

  /**
   * Maps the grouped fields into their display fields.
   * May return multiple fields (for the date virtual field).
   */
  private getFields(resource: WorkPackageResource, fieldNames: string[]): FieldDescriptor[] {
    const descriptors: FieldDescriptor[] = [];

    fieldNames.forEach((fieldName: string) => {
      if (fieldName === "date") {
        descriptors.push(this.getDateField(resource));
        return;
      }

      if (!resource.schema[fieldName]) {
        debugLog("Unknown field for current schema", fieldName);
        return;
      }

      const field: DisplayField = this.displayField(resource, fieldName);
      descriptors.push({
        name: fieldName,
        label: field.label,
        multiple: false,
        spanAll: field.isFormattable,
        field: field
      });
    });

    return descriptors;
  }

  /**
   * We need to discern between milestones, which have a single
   * 'date' field vs. all other types which should display a
   * combined 'start' and 'due' date field.
   */
  private getDateField(resource: WorkPackageResource): FieldDescriptor {
    let object: any = {
      name: "date",
      label: this.I18n.t("js.work_packages.properties.date"),
      multiple: false
    };

    if (resource.schema.hasOwnProperty("date")) {
      object.field = this.displayField(resource, "date");
    } else {
      object.fields = [this.displayField(resource, "startDate"), this.displayField(resource, "dueDate")];
      object.multiple = true;
    }

    return object;
  }

  /**
   * Get the current resource context change from the WP resource.
   * Used to identify changes in the schema or project that may result in visual changes
   * to the single view.
   *
   * @param {WorkPackageChangeset} change
   * @returns {SchemaContext}
   */
  private contextFrom(change: WorkPackageChangeset): ResourceContextChange {
    let schema = change.schema;
    let workPackage = change.projectedResource;

    let schemaHref: string | null = null;
    let projectHref: string | null = workPackage.project && workPackage.project.href;

    if (schema.baseSchema) {
      schemaHref = schema.baseSchema.href;
    } else {
      schemaHref = schema.href;
    }

    return {
      isNew: workPackage.isNew,
      schema: schemaHref,
      project: projectHref
    };
  }

  private displayField(resource: WorkPackageResource, name: string): DisplayField {
    return this.displayFieldService.getField(resource, name, resource.schema[name], {
      container: "single-view",
      injector: this.injector,
      options: {}
    }) as DisplayField;
  }

  private getAttributesGroupId(group: any): string {
    let overflowingIdentifier = this.$element
      // .find("[data-group-name='" + group.name + "']")
      .find('[data-group-name="' + group.name + '"]')
      .data(overflowingContainerAttribute);

    if (overflowingIdentifier) {
      return overflowingIdentifier.replace(".__overflowing_", "");
    } else {
      return "";
    }
  }
  public getStringValue(value:any) {
    return JSON.stringify(value)
  }
  //获取新增task fileRefNo 数据
  public getFileRefNo(newChange: WorkPackageChangeset, isworkpageChange?:boolean) {
    const project_id = this.workPackage.project.id;
    const type = this.workPackage.type.name;
    const csrf_token: string | undefined = jQuery("meta[name=csrf-token]").attr("content");
    let newHeaders: any = {};
    newHeaders["X-Authentication-Scheme"] = "Session";
    newHeaders["X-Requested-With"] = "XMLHttpRequest";

    if (csrf_token) {
      newHeaders["X-CSRF-TOKEN"] = csrf_token;
    }
    let self = this;
    this.http
      .get(
        `api/v3/file_ref_no/create_form_data?project_id=${project_id}&target_type=${type}`,
        {
          withCredentials: true,
          headers: newHeaders
        }
      )
      .subscribe((res: any) => {
        this.refresh(newChange);
        this.zone.run(() => {
          if (res.data.is_dwss_type) {
            if(isworkpageChange) {

            }else {

              this.isFileRefNo = true;
            }
            this.fileRefNo = res.data;
            if (this.fileRefNo.values.length > 0) {
              this.fileRefNoType = JSON.stringify(this.fileRefNo.values[0].value);
            } else {
              this.fileRefNoType = '-';
              this.fileRefNo.values[0] = {
                id: '0',
                value: '-'
              };
            }
            let fileRefNoType = JSON.parse(this.fileRefNoType)
            this.hook.globalVar.next([{ prefix: this.fileRefNo.prefix, type_value: fileRefNoType }]);
          } else {
            if(isworkpageChange) {

            }else {

              this.isFileRefNo = false;
            }
          }
        });
      });
  }
  //获取task fileRefNo 数据
  public getFileRefNoInfo(newChange: WorkPackageChangeset, isworkpageChange?:boolean) {
    const obj_id = this.workPackage.id;
    const obj_type = 'WorkPackage';

    const csrf_token: string | undefined = jQuery("meta[name=csrf-token]").attr("content");
    let newHeaders: any = {};
    newHeaders["X-Authentication-Scheme"] = "Session";
    newHeaders["X-Requested-With"] = "XMLHttpRequest";

    if (csrf_token) {
      newHeaders["X-CSRF-TOKEN"] = csrf_token;
    }
    this.http
      .get(
        `api/v3/file_ref_no/view_form_data?obj_id=${obj_id}&obj_type=${obj_type}`,
        {
          withCredentials: true,
          headers: newHeaders
        }
      )
      .subscribe((res: any) => {
        this.is_project_admin = res.current_user_is_project_admin;
        this.roleJudgment(newChange, res.current_user_is_project_admin);
        this.refresh(newChange);
        this.zone.run(() => {
          if (res.is_dwss_type) {
            if(isworkpageChange) {

            }else {
              this.isFileRefNo = true;

            }
            this.fileRefNoInfo = res.data;
          } else {
            if(isworkpageChange) {

            } else {

              this.isFileRefNo = false;
            }
          }
        });
      });
  }
  // type选择事件
  public fileRefNoSelect() {
    // console.log('fileRefNoSelect',this.fileRefNoType);
    let fileRefNoType = JSON.parse(this.fileRefNoType)
    this.hook.globalVar.next([{ prefix: this.fileRefNo.prefix, type_value: fileRefNoType }]);
  }

  //risc权限显示
  public adminGroupRisc(data: any) {
    if (this.workPackage.type.name == 'RISC'|| this.workPackage.type.name ==  "RISC(inspection)"  || this.workPackage.type.name == 'RISC(EM)') {
      if (this.workPackage.id == 'new') {
        if (data.name == 'RISC FILE ID') {
          return true
        } else {
          return false
        }
      }

        return true

    } else {
      return true
    }


  }

  public pdfdatadis() {
    if (!this.isRiscForm) {
      console.log('触发不了吗？', jQuery('#riscformpdf'))
    
        jQuery('#riscformpdf').trigger('click');
  
    } else {
      alert('aasasa')
      jQuery('#singleviewriscloding').css('display', 'block')
      this.pdfSrc = `${window.location.origin}/api/v3/work_packages/${this.workPackage.id}/export?${Math.random()}`
    }
  }

  callBackFn(pdf: PDFDocumentProxy) {
    //  使用“ pdf”执行任何操作
    console.log(pdf)
    jQuery('#singleviewriscloding').css('display', 'none')
  }

  onError(error: any) {
    // do anything
    console.log(error)
  }

  public assignNext(data: any) {
    if(!this.canAssigntNext) {
      return
    }
    this.assignNextdipsle = !this.assignNextdipsle
    console.log('assignNext')
  }

  get_custom_field_group_name(ch:any){
    const that = this
    let item=0
    this.workPackage._attributeGroups.forEach((element:any,index:any) => {
      that.http.get(`/api/v3/work_packages/${this.workPackage.id}/allow_to_edit_risc?custom_field_group_name=${element.name}`).subscribe((res:any)=>{
        item +=1
        this.customFieldGroup[element.name] = res.allow
        if(this.workPackage._attributeGroups.length == item){
          console.log('最后一个了w',element.name)
          const change = this.halEditing.changeFor(this.workPackage);
          change.getForm()
          ch && ch()
        }
        //     console.log(!res.allow)
      })
    });
  }

  //分享PDF
  sharePDF(event:any){
    this.showShare = !this.showShare;
  }
  checkedBack(event: any) {
    this.sharePDF = event;
  }

  hasPhotoLog(){
    this.isPhotoLog = true
    if(this.workPackage.type.name ==="Inspection"){
      this.showmark = true
      let imageid =localStorage.getItem('imageid')
      if(imageid){
        this.showPhotolog = true
        this.imageArr = imageid.split(",")
          for(let item of this.imageArr){
            this.phservice.getDetailpage(item).subscribe((res:any)=>{
              let data = {
                id:item,
                image_url :res.image_url
              }
              this.Photo_log.push(data)
              this.isPhotoLog = false
            })
          }
      }else{
        this.showPhotolog =false
        this.isPhotoLog = false
        localStorage.removeItem('imageid')
      }
      
    }
  }
  //删除图片
  todelete(e:any){
    console.log(e)
    console.log(this.imageArr)
　let  index = this.imageArr.indexOf(Number(e));
    this.imageArr.splice(index, 1)
    let newImageId = this.imageArr.toString()
    localStorage.setItem('imageid',newImageId)
    this.Photo_log=[]
    this.hasPhotoLog()


  }

}


export function forEach(arg0: (item: any) => void) {
  throw new Error("Function not implemented.");
}

