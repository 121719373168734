import { Injector } from '@angular/core';
import { debugLog } from '../../../../helpers/debug_output';
import { tableRowClassName } from '../../builders/rows/single-row-builder';
import { timelineCellClassName } from '../../builders/timeline/timeline-row-builder';
import { uiStateLinkClass } from '../../builders/ui-state-link-builder';
import { WorkPackageTable } from '../../wp-fast-table';
import { ContextMenuHandler } from './context-menu-handler';
import { WorkPackageViewSelectionService } from "core-app/modules/work_packages/routing/wp-view-base/view-services/wp-view-selection.service";
import { HttpClient } from '@angular/common/http';

export class ContextMenuRightClickHandler extends ContextMenuHandler {
  public http = this.injector.get(HttpClient)
  readonly wpTableSelection = this.injector.get(WorkPackageViewSelectionService);
  public userData:any
  constructor(public readonly injector: Injector,
    table: WorkPackageTable,
) {
  
    super(injector, table);
  }

  public get EVENT() {
    return 'contextmenu.table.rightclick';
  }

  public get SELECTOR() {
    return `.${tableRowClassName},.${timelineCellClassName}`;
  }

  public eventScope(table: WorkPackageTable) {
    return jQuery(table.tableAndTimelineContainer);
  }
  public async getUser() {
    this.user  =  this.http.get(`/api/v3/users/${document.getElementsByName('current_user')[0].getAttribute('data-id')}`)

  }
  public  handleEvent(table: WorkPackageTable, evt: JQuery.TriggeredEvent): boolean {
    if (!table.configuration.contextMenuEnabled) {
      return false;
    }
    let target = jQuery(evt.target);
    this.getUser()
    // We want to keep the original context menu on hrefs
    // (currently, this is only the id
    if (target.closest(`.${uiStateLinkClass}`).length) {
      debugLog('Allowing original context menu on state link');
      return true;
    }
    evt.preventDefault();
    evt.stopPropagation();

    // Locate the row from event
    const element = target.closest(this.SELECTOR);
    const wpId = element.data('workPackageId');
    console.log(table.originalRowIndex[wpId].object.status.name);
    console.log(table.originalRowIndex[wpId].object.type.name);
    //当前点击的status 
    let currentStatus = table.originalRowIndex[wpId].object.status.name
    //当前点击的type
    let currentType = table.originalRowIndex[wpId].object.type.name
    
    //只有当前点击的类型是RISC 并且 Closed with disapproval状态下，触发右键单击事件，否则不会触发
    if (currentType == 'RISC' || currentType == 'RISC(inspection)' || currentType == 'RISC(EM)') {
      this.user  =  this.http.get(`/api/v3/users/${document.getElementsByName('current_user')[0].getAttribute('data-id')}`).subscribe ( (user:any) => {

        let userIs39 = user.groups.some((item:any) => item.id == 39)
        let userIs36 =  user.groups.some((item:any) => item.id == 36)
        if (currentStatus == 'Closed with disapproval' ||  (currentStatus == 'New' && userIs36) || (currentStatus == 'Submitted' || currentStatus == 'Delegated' && userIs39)) {
          if (wpId) {
            let [index,] = this.table.findRenderedRow(wpId);
  
            if (!this.wpTableSelection.isSelected(wpId)) {
              this.wpTableSelection.setSelection(wpId, index);
            }
  
            super.openContextMenu(evt, wpId, null ,currentStatus);
          }
        }
      } )
    } else {
      if (wpId) {
        let [index,] = this.table.findRenderedRow(wpId);

        if (!this.wpTableSelection.isSelected(wpId)) {
          this.wpTableSelection.setSelection(wpId, index);
        }

        super.openContextMenu(evt, wpId);
      }
    }
    return false;
  }
}
