<!-- <ng-container ngIf='!displayquest'> -->
  <div class="check-titleTemplate" *ngIf="showNewtemplate || buttonnewdips">
    <div class="toolbar-container">
      <div class="toolbar">
          <div class="title-container" style="display: flex;">
            <h2  class="titleName" *ngIf="kind_id == 1">CHECKLISTS</h2>
            <h2  class="titleName" *ngIf="kind_id == 2">Daily Cleansing</h2>
            <h2  class="titleName" *ngIf="kind_id == 3">Weekly Tidying</h2>
            <h2  class="titleName" *ngIf="kind_id == 4">Weekly Environmental Walk Inspection</h2>
            <h2  class="titleName" *ngIf="kind_id == 5">Weekly Safety Walk Inspection</h2>
            <h2 class="titleName" *ngIf="kind_id == 6">Monthly Safety Pertormance</h2>
            <h2 class="titleName" *ngIf="kind_id == 8">Early Warning</h2>
            <h2 class="titleName" *ngIf="kind_id == 9">Contractor's Notif.of CE</h2>
            <h2 class="titleName" *ngIf="kind_id == 10">Interim Assessment</h2>
            <h2 class="titleName" *ngIf="kind_id == 11">MDM Setting</h2>
          </div>
      </div>
    </div>
  </div>
  <div class="titleclss" >
  <div class="check-title" *ngIf="!buttonnewdips&&!showNewtemplate">
    <div class="toolbar-container">
      <div class="toolbar">
          <div class="title-container" style="display: flex;">
            <h2  class="titleName" *ngIf="kind_id == 1">CHECKLISTS</h2>
            <h2  class="titleName" *ngIf="kind_id == 2">Daily Cleansing</h2>
            <h2  class="titleName" *ngIf="kind_id == 3">Weekly Tidying</h2>
            <h2  class="titleName" *ngIf="kind_id == 4">Weekly Environmental Walk Inspection</h2>
            <h2  class="titleName" *ngIf="kind_id == 5">Weekly Safety Walk OLD</h2>
            <h2 class="titleName" *ngIf="kind_id == 6">Monthly Safety Performance</h2>
            <h2  class="titleName" *ngIf="kind_id == 7">Weekly Safety Walk</h2>
            <h2 class="titleName" *ngIf="kind_id == 8">Early Warning</h2>
            <h2 class="titleName" *ngIf="kind_id == 9">Contractor's Notif.of CE</h2>
            <h2 class="titleName" *ngIf="kind_id == 10">Interim Assessment</h2>
            <h2 class="titleName" *ngIf="kind_id == 11">MDM Setting</h2>
          </div>
      </div>
    </div>

    <div class="createbtn" >
      <ul class="toolbar-items hide-when-print ">
        <li style="margin-right: 20px;" *ngIf="kind_id==2||kind_id==3||kind_id==4||kind_id==7">
            <button class="button -alt-highlight add-work-package" (click)="openDownLoadDialog()">
            <span class="button--text"
                  aria-hidden="true">Download Excel</span>
          </button>
        </li>
        <li style="margin-right: 20px;" *ngIf="isUserAdmin&&kind_id!=11">
          <button class="button -alt-highlight add-work-package" (click)="dianjishijian()">
            <op-icon icon-classes="button--icon icon-add"></op-icon>
            <span class="button--text"
                  aria-hidden="true">{{ i18n.t("js.check-lists.template.newtemplate") }}</span>
            <op-icon icon-classes="button--icon icon-small icon-pulldown hidden-for-mobile"></op-icon>
          </button>
        </li>
        <li class="toolbar-item">

          <button class="button -alt-highlight add-work-package" *ngIf="create_power&&kind_id!=11" (click)="TemList()">
            <op-icon icon-classes="button--icon icon-add"></op-icon>
            <span class="button--text"
                  aria-hidden="true">{{ i18n.t("js.check-lists.create") }}</span>
            <op-icon icon-classes="button--icon icon-small icon-pulldown hidden-for-mobile"></op-icon>
          </button>
          <ng-container *ngIf="showTemList">
          <div class="buttonnew">
            <ul>
              <li *ngFor="let item of newtemlanlist" (click)='newtemitemclick(item)'>{{item.name}}</li>
            </ul>
          </div>

        </ng-container>

        </li>
        <li class="toolbar-item">
          <zen-mode-toggle-button class="zemmodebutton">
          </zen-mode-toggle-button>
        </li>
      </ul>
    </div>
  </div>

  <check_lists-template *ngIf="buttonnewdips" (dataObject)="getDataObject($event)" [saveObj]="saveObj" [kind_id]="kind_id"    [changeMoudulelist]="changeMoudulelist" id="listemplate"></check_lists-template>
  <div class="newtemplate">
    <check_lists-newtemplate *ngIf="showNewtemplate" [kind_id]="kind_id"  (backStep)="backStep()"  (closeTep)="closeTep()" [dataObjectInfo]="dataObject"  (finish)="finish($event)" [yesIssue]="yesIssue" [updatetemplate]="updatetemplate" [dataitem]='changeMoudulelist'></check_lists-newtemplate>
  </div>

  <div class="nav" *ngIf="buttonnewdips || showNewtemplate">
    <check_lists-nav  [nav_pos]="nav_pos" [changepos]="changepos"  (nextstep)="nextStep()" (showLists)="showLists()" [showNext]="showNext"  (getIssue)="getIssue()" [showFinish]="showFinish"></check_lists-nav>
  </div>
  <div _ngcontent-gja-c2="" class="work-packages-split-view" style="height: 100%;"  *ngIf="displaylist" >
    <div _ngcontent-gja-c2="" class="work-packages-split-view--tabletimeline-side loading-indicator--location" data-indicator-name="table">
      <!-- 控制显示列表或者模板 -->
      <div class="btndiv">
        <ng-container  *ngIf="isUserAdmin">
          <button class="checklist_button" (click)="show_checklist('formlist')" [ngStyle]="{'background' : showChecklist=='formlist' ? '#fff' : '#eeeeed' , 'color' :  showChecklist=='formlist' ? ' ': '#333'}" >{{ kind_id==11? i18n.t("js.check-lists.list-title.Device_List") : i18n.t("js.check-lists.formlist") }}</button>

          <button *ngIf="kind_id!=11" class="checklist_button" (click)="show_checklist('templatelist')"[ngStyle]="{'background' : showChecklist=='templatelist' ? '#fff' : '#eeeeed' , 'color' :  showChecklist=='templatelist' ? ' ': '#333'}" >{{ i18n.t("js.check-lists.templatelist") }}</button>
        </ng-container>
        <!-- <button class="checklist_button" (click)="show_checklist('weeklylist')" [ngStyle]="{'background' : showChecklist=='weeklylist' ? '#fff' : '#eeeeed' , 'color' :  showChecklist=='weeklylist' ? ' ': '#333'}" >Weekly</button>

        <button class="checklist_button" (click)="show_checklist('monthlylist')" [ngStyle]="{'background' : showChecklist=='monthlylist' ? '#fff' : '#eeeeed' , 'color' :  showChecklist=='monthlylist' ? ' ': '#333'}" >Monthly</button>

        <button class="checklist_button" (click)="show_checklist('envilist')" [ngStyle]="{'background' : showChecklist=='envilist' ? '#fff' : '#eeeeed' , 'color' :  showChecklist=='envilist' ? ' ': '#333'}" >Environmental</button>

        <button class="checklist_button" (click)="show_checklist('safetylist')" [ngStyle]="{'background' : showChecklist=='safetylist' ? '#fff' : '#eeeeed' , 'color' :  showChecklist=='safetylist' ? ' ': '#333'}" >Safety Performance Records</button>  -->

        </div>
      <div  *ngIf="kind_id != 11">
        <div *ngIf="kind_id == 8 || kind_id == 9 || kind_id == 10" >
          <div class="filter" *ngIf="showChecklist != 'templatelist' && kind_id != 6 && kind_id != 5">
            <a [ngClass]="{'filterSelect': action == 'MyAction'}" (click)="getList('MyAction')">MyAction</a>
            <span>|</span>
            <a [ngClass]="{'filterSelect': action == 'New'}" (click)="getList('New')">New</a>
            <span>|</span>
            <a [ngClass]="{'filterSelect': action == 'ContractorSent'}" (click)="getList('ContractorSent')">ContractorSent</a>
            <span>|</span>

            <a *ngIf="kind_id == 10" [ngClass]="{'filterSelect': action == 'Closed'}" (click)="getList('Closed')">Closed</a>
            <span *ngIf="kind_id == 10">|</span>

            <a *ngIf="kind_id == 8" [ngClass]="{'filterSelect': action == 'ConsultantSent'}" (click)="getList('ConsultantSent')">ConsultantSent</a>
            <span *ngIf="kind_id == 8">|</span>

            <a *ngIf="kind_id == 8" [ngClass]="{'filterSelect': action == 'Noted'}" (click)="getList('Noted')">Noted</a>
            <span *ngIf="kind_id == 8">|</span>

            <a *ngIf="kind_id == 9" [ngClass]="{'filterSelect': action == 'Is_CE'}" (click)="getList('Is_CE')">Is_CE</a>
            <span *ngIf="kind_id == 9">|</span>

            <a *ngIf="kind_id == 9" [ngClass]="{'filterSelect': action == 'Not_CE'}" (click)="getList('Not_CE')">Not_CE</a>
            <span *ngIf="kind_id == 9">|</span>

            <a [ngClass]="{'filterSelect': action == 'All'}" (click)="getList('All')">All</a>

          </div>
        </div>
        <div  *ngIf="kind_id != 8 && kind_id != 9 && kind_id != 10">
          <div class="filter" *ngIf="showChecklist != 'templatelist' && kind_id != 6 && kind_id != 5">
            <a [ngClass]="{'filterSelect': action == 'MyAction'}" (click)="getList('MyAction')">MyAction</a>
            <span>|</span>

            <a [ngClass]="{'filterSelect': action == 'New'}" (click)="getList('New')">New</a>
            <span>|</span>

            <a [ngClass]="{'filterSelect': action == 'ContractorSent'}" (click)="getList('ContractorSent')">ContractorSent</a>
            <span>|</span>

            <a *ngIf="kind_id != 2 && kind_id != 4 && kind_id != 5 && kind_id != 7" [ngClass]="{'filterSelect': action == 'ContractorCompleted'}" (click)="getList('ContractorCompleted')">ContractorCompleted</a>
            <span *ngIf="kind_id != 2 && kind_id != 4 && kind_id != 5 && kind_id != 7">|</span>

            <a [ngClass]="{'filterSelect': action == 'Closed'}" (click)="getList('Closed')">Closed</a>
            <span>|</span>

            <a *ngIf="kind_id != 4 && kind_id != 5 && kind_id != 7" [ngClass]="{'filterSelect': action == 'ContractorRecheck'}" (click)="getList('ContractorRecheck')">ContractorRecheck</a>
            <span *ngIf="kind_id != 4 && kind_id != 5 && kind_id != 7">|</span>

            <a *ngIf="kind_id != 2" [ngClass]="{'filterSelect': action == 'ConsultantSent'}" (click)="getList('ConsultantSent')">ConsultantSent</a>
            <span *ngIf="kind_id != 2">|</span>

            <a [ngClass]="{'filterSelect': action == 'All'}" (click)="getList('All')">All</a>

          </div>
        </div>
      </div>

      <check_lists-list style="height:100%" [kind_id]="kind_id" [checklistsindexitem]='checklistsindexitem' (clicklistid)='clicklistid($event)' (sortType)="changeSort($event)"  *ngIf="showChecklist != 'templatelist'"></check_lists-list>
      <check_lists-modulelist  *ngIf="showChecklist == 'templatelist'"  [modulelist]="modulelist"  (moduleListid)='moduleListid($event)'  (editmoduleListid) ="editmoduleListid($event)" style="height: 100%;"></check_lists-modulelist>
    </div>
  </div>

    <!-- checklist分页 -->
<ng-container  *ngIf="showChecklist == 'formlist'">
  <div *ngIf="perlength >= 1 && !buttonnewdips&&!showNewtemplate" class="pagination" style="position: absolute; bottom: 10px;">
    <nav class="pagination--pages">
      <ul class="pagination--items">
        <li class="pagination--item" *ngIf="page-1 != 0"><a href="" (click)='perclicklink("prev")' id="pagination--prev-link" rel="prev start" role="link" aria-label="Back to the previous page">&lt;</a></li>
        <li *ngFor="let item of perlist;let i = index" class="pagination--item pagination-number" ng-reflect-klass="pagination--item pagination-nu" ng-reflect-ng-class="[object Object]">
          <a [ngClass]="{peracss: page==i+1}" (click)='perclick(i+1)' href="" rel="next" role="link">{{i+1}}</a></li>
        <li class="pagination--item" *ngIf="page != perlength"><a (click)='perclicklink("next")' class="pagination--next-link" href="" id="pagination--next-link" rel="next" role="link" aria-label="Forward to the next page">&gt;</a></li>
      </ul>
    </nav>
  </div>
</ng-container>

  <!-- modulelist 分页 -->
  <ng-container   *ngIf="showChecklist == 'templatelist'&&!buttonnewdips&&!showNewtemplate">
   <div class="pagination" style="position: absolute; bottom: 10px;">
    <ul class="pagination--items">
      <li class="pagination--item" *ngIf="modulepage-1 != 0"><a href="" (click)='modulego("prev")' id="pagination--prev-link" rel="prev start" role="link" aria-label="Back to the previous page">&lt;</a></li>
      <li *ngFor="let item of modulesize;let i = index" class="pagination--item pagination-number" ng-reflect-klass="pagination--item pagination-nu" ng-reflect-ng-class="[object Object]">
        <a [ngClass]="{peracss:modulepage==i+1}" (click)='modulelistpage(i+1)' href="" rel="next" role="link">{{i+1}}</a></li>
                <li class="pagination--item" *ngIf="modulepage != modulesize.length"><a (click)='modulego("next")' class="pagination--next-link" href="" id="pagination--next-link" rel="next" role="link" aria-label="Forward to the next page">&gt;</a></li>
     </ul>
   </div>
  </ng-container>

</div>
<!-- </ng-container> -->

<!-- <ng-container ngIf='displayquest'> -->

<check_lists-quest *ngIf="displayquest && (kind_id == 12||kind_id == 1 || kind_id == 2 || kind_id == 3 || kind_id == 7)" (close)='close()'  [check_listsID]='check_listsID'  [kind_id]="kind_id"></check_lists-quest>
<check_list-quest2 *ngIf="displayquest && (kind_id == 5 )" (close)='close()' [check_listsID]='check_listsID'  [kind_id]="kind_id"></check_list-quest2>
<check_list-quest3 *ngIf="displayquest && kind_id == 4" (close)='close()' [check_listsID]='check_listsID'  [kind_id]="kind_id"></check_list-quest3>
<check_lists-quest4 *ngIf="displayquest && kind_id == 6" (close)='close()' [check_listsID]='check_listsID'  [kind_id]="kind_id"></check_lists-quest4>
<check_list-quest5 *ngIf="displayquest && (kind_id == 8 || kind_id == 9 || kind_id == 10)" (close)='close()' [check_listsID]='check_listsID'  [kind_id]="kind_id"></check_list-quest5>
<check_list-templateCopy></check_list-templateCopy>
<check_list-create class="createDialog" [template]="template" (closeEmit)="createEvent($event)" *ngIf="template"></check_list-create>

<!-- </ng-container> -->