<div class="project-list">
  <div class="project-list-box" *ngFor="let item of projectList; let i = index">
    <div class="project-list-box-title">
      <div class="title-text">
        <div class="title-text-left">
          {{ item.name }}
        </div>
        <div class="title-text-right" *ngIf="item.attachment_url">
          <a class="icon-one" id="projectIcon" [href]="item.attachment_url" target='_blank'></a>
          <a class="icon-two"  id="projectIcon" [href]="item.attachment_url" target='_blank'></a>
        </div>
      </div>
      <div class="title-date">
        Finish Date: <span>{{ item.finish_date }}</span>
      </div>
      <div class="title-btn">
        <a [href]="projectPath(item.identifier)">
          <span>
            Enter
          </span>
          <i class="iconfont icon-you"></i>
        </a>
      </div>
    </div>
    <div class="project-list-box-mobile">
      <div class="mobile-date">
        Finish Date: <span>{{ item.finish_date }}</span>
      </div>
      <div class="mobile-info">
        <div class="info-item">
            <div class="info-item-text">
              <a [href]="workPackagesPath(item.identifier, 'New')">{{ item.task.new }}</a>
            </div>
            <div class="info-item-title">
              new
            </div>
          </div>
          <div class="info-item">
            <div class="info-item-text">
              <a [href]="workPackagesPath(item.identifier, 'In progress')">{{ item.task.in_progress }}</a>
            </div>
            <div class="info-item-title">
              In progress
            </div>
          </div>
          <div class="info-item">
            <div class="info-item-text">
              <a [href]="workPackagesPath(item.identifier, 'Closed')">{{ item.task.closed }}</a>
            </div>
            <div class="info-item-title">
              Closed
            </div>
          </div>
          <div class="info-item">
            <div class="info-item-text">
              <a [href]="workPackagesPath(item.identifier, 'On hold')">{{ item.task.on_hold }}</a>
            </div>
            <div class="info-item-title">
              On hold
            </div>
          </div>
          <div class="info-item">
            <div class="info-item-text">
              <a [href]="workPackagesPath(item.identifier, 'Rejected')">{{ item.task.rejected }}</a>
            </div>
            <div class="info-item-title">
              Rejected
            </div>
          </div>
      </div>
    </div>
    <div class="loading-box" [class.loading]="loadingList[i]">
      <div class="loading-spinner" *ngIf="loadingList[i]">
        <div class="spinner"></div>
      </div>
      <div class="project-list-box-info">
        <div class="info-item">
          <div class="info-item-text">
            <a [href]="workPackagesPath(item.identifier, 'New')">{{ item.risc_overview?item.risc_overview.risc_survey:0 }}</a>
          </div>
          <div class="info-item-title">
            <div>RISC</div>
            <div>
              (Survey)
            </div>
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-text">
            <a [href]="workPackagesPath(item.identifier, 'In progress')">{{ item.risc_overview?item.risc_overview.risc_work:0 }}</a>
          </div>
          <div class="info-item-title">
            <div>RISC</div>
            <div>
              (Works)
            </div>
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-text">
            <a [href]="workPackagesPath(item.identifier, 'Closed')">{{ item.risc_overview?item.risc_overview.site_diarys:0  }}</a>
          </div>
          <div class="info-item-title">
            <div>Site</div>
            <div>
              Diary
            </div>
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-text">
            <a [href]="workPackagesPath(item.identifier, 'On hold')">{{ item.risc_overview?item.risc_overview.labor_return :0 }}</a>
          </div>
          <div class="info-item-title">
            <div>Labor</div>
            <div>
              Return
            </div>
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-text">
            <a [href]="workPackagesPath(item.identifier, 'Rejected')">{{ item.risc_overview?item.risc_overview.daily_cleansing:0  }}</a>
          </div>
          <div class="info-item-title">
            <div>Daily</div>
            <div>
              Cleansing
            </div>
          </div>
        </div>

      </div>
      <div class="project-list-box-info">
        <div class="info-item">
          <div class="info-item-text">
            <a [href]="workPackagesPath(item.identifier, 'New')">{{ item.risc_overview?item.risc_overview.weekly_tidying:0  }}</a>
          </div>
          <div class="info-item-title">
            <div>Weekly</div>
            <div>
              Tidying
            </div>
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-text">
            <a [href]="workPackagesPath(item.identifier, 'In progress')">{{ item.risc_overview?item.risc_overview.weekly_env_walk:0  }}</a>
          </div>
          <div class="info-item-title">
            <div>Weekly</div>
            <div>
              Env Walk
            </div>
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-text">
            <a [href]="workPackagesPath(item.identifier, 'Closed')">{{ item.risc_overview?item.risc_overview.weekly_safety_walk:0  }}</a>
          </div>
          <div class="info-item-title">
            <div>Weekly</div>
            <div>
              Safety Walk
            </div>
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-text">
            <a [href]="workPackagesPath(item.identifier, 'On hold')">{{ item.risc_overview?item.risc_overview.send : 0 }}</a>
          </div>
          <div class="info-item-title">
            SEDN
          </div>
        </div>
        <div class="info-item">

        </div>

      </div>
  </div>
  </div>
</div>