<div [ngClass]="[
      editFieldContainerClass,
      fieldName,
      active && '-active' || '',
      fieldLable === 'BIM' ? 'BIMclass' : '',
      wrapperClasses || '-small'
     ]"
     (dragover)="startDragOverActivation($event)">

  <div #editContainer
       [hidden]="!active">
  </div>

  <div (accessibleClick)="activateIfEditable($event)"
       [accessibleClickStopEvent]="false"
       [hidden]="active"
       tabindex="-1"
       class="fieldWidth"
       [ngClass]="{
          'customField': this.fieldName.includes('customField')
       }"
       #displayContainer>
  </div>
  <button class="BIMbtn" *ngIf="fieldLable == 'BIM'">View BIM</button>

</div>
