// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++

import {Component, Injector, Input, ChangeDetectorRef} from '@angular/core';
import {I18nService} from 'core-app/modules/common/i18n/i18n.service';
import {FieldDescriptor, GroupDescriptor} from 'core-components/work-packages/wp-single-view/wp-single-view.component';
import {WorkPackageResource} from 'core-app/modules/hal/resources/work-package-resource';
import {EditFormComponent} from "core-app/modules/fields/edit/edit-form/edit-form.component";
import { HttpClient } from '@angular/common/http';
import {HandlerCheckObserveService} from '../../components/HandlerCheckObserve.service'
declare let layui: any
//判断3有没有被选中，选中就启用4 没有禁用4
@Component({
  selector: 'wp-attribute-group',
  styleUrls: ['./wp-attribute-group.sass'],
  templateUrl: './wp-attribute-group.template.html'
})
export class WorkPackageFormAttributeGroupComponent {
  @Input() public workPackage:WorkPackageResource;
  //group 渲染规则
  @Input() public group:GroupDescriptor;
  @Input() public groupimgdata:any;
  @Input() public signusername:any;
  @Input() public signuserzhu:any;
  @Input() public groupedFields: any
  @Input() public is_project_admin: any
  //服务
  //Inspection detail选项卡中渲染的文本数组
  public textArr:any[] = [];
  public groupitem=0
  public dipslesignature = false
  public signatureImage:any
  public signloding:any
  public memberssignitem:any = []
  public customFieldGroupPrincipal:any = []
  public selectCustomFieldGroup:any = null
  public okselect:boolean = true
  public numer:any =[]
  public check = false
  public isDefoultSign =  false
  public currentUser:any = document.querySelector('meta[name=current_user]')
  public defaultSign:any
  public checlist:any = {
    'There is no objection to you proceeding with the work.': false,
    'Survey record has been taken': false,
    'The following deficiencies have been noted:': false,
    'Work reinspected, no objection to you proceeding.': false ,
    // 'Detail of deficiencies': false,
    'Not Ready':false

  }
  public checkdiabale:any = {
    'There is no objection to you proceeding with the work.': false,
    'Survey record has been taken': false,
    'The following deficiencies have been noted:': false,
    'Work reinspected, no objection to you proceeding.': false ,
    // 'Detail of deficiencies': false,
    'Not Ready':false
  }
  requestHeader: any = {
    'X-Authentication-Scheme': 'Session',
    'X-Requested-With': 'XMLHttpRequest'
  };
  public formData:any=[
    {
      id:'',
      label:'Portion:',
      value:'',
      input_type:'select',
      options:[],
      inputValue: '',
      optionsValue:[]
    }
  ]
  //API前缀
  apiPrefix: any = '/api/v3';
  public itemlabel = [
    'There is no objection to you proceeding with the work.',
    'Survey record has been taken',
    'The following deficiencies have been noted:',
    'Work reinspected, no objection to you proceeding.' ,
    // 'Detail of deficiencies',
    'Not Ready'
  ]
  public text = {
    date: {
      startDate: this.I18n.t('js.label_no_start_date'),
      dueDate: this.I18n.t('js.label_no_due_date')
    },
  };
  public mustNotBeBlank = [
    'Document Reference',
    '(1) Location of Work',
    '(2) Work to be inspected/surveyed',
    '(2) Date & Time',
    '(3) Work Proposed after Approval of (2)',
   ]

   public checkFun:any
  public ngOnInit() {

    // console.log(this.groupimgdata,'asdasdasdasdasdadasd')
    if(this.workPackage.id != 'new')this.get_custom_field_group_available_principal(this.group.name)
    if(this.workPackage.type.name != 'RISC' && this.workPackage.type.name != 'RISC(inspection)' && this.workPackage.type.name != 'RISC(EM)') return
    this.loadDefault()
    this.group.members.forEach( (descriptor:any) => {
      this.checlist[descriptor.label.trim()] = this.workPackage[descriptor.name]
    }) 
    this.checkRELOWLS()
    this.checkFun = this.detailSet()
    this.checkFun.pushList([0,1], [2,3,4])
    this.checkFun.pushList([0], [2,3,4])
    this.checkFun.pushList([2], [0,3,4])
    this.checkFun.pushList([1], [3,4])
    this.checkFun.pushList([3], [0,1,2,4])
    this.checkFun.pushList([4], [0,1,2,3])
    this.checkFun.setCheckList()
    this.checkFun.run()
    this.group.members.forEach((res:any)=>{
       if(!res.field.writable) this.okselect = false
       if(res.label.indexOf('Signature')>=0 ){
         this.memberssignitem.push(res)
       }
      })
      if(!this.group.status.find( (item:any) => {
        return item.id == this.workPackage.status.id
      }) && !this.is_project_admin) {
        this.okselect = false
      }
      if(this.workPackage.status.name == 'New'){
      this.okselect = true
      }
      //判断Inspection 选项卡进行操作
      if(this.group.name == 'Inspection detail'){
        //创建新数组testArr
        this.group.members.forEach((item) =>{
          if(this.itemlabel.indexOf(item.label.trim()) != -1){
            this.textArr.push(item.label) //修改的四个选项卡的文字字段
    

          }
        })
      }
    this.group.members.forEach((item:any)=>{
      
      if(item.label.indexOf('Location of Work')>=0&&item.field.attribute.raw){
       let data = JSON.parse(item.field.attribute.raw)
        let idArr = data.id.split(',')
        console.log(idArr,'idArr')
        let other = data.other
        const el:any = document.querySelector('.openproject-base--ui-view')
        if(el){
          el.addEventListener('click',()=>{
            this.showDialogIndex = null
          })
        }
        
        this.initAddressData().then((res:any)=>{
          idArr.forEach((item:any,index:any)=>{
            this.selectAddress(index,{input_type:'select',id:item},false)
          })
          if(data.hasOwnProperty('other')){
            let arr = ['ABWF works','E&M works','Other','Foundation works','Landscape works']
            const label = arr.includes(this.formData[idArr.length-1].value) ? '' : 'specific works:'
            this.formData[idArr.length] = {
              id: '',
              label: label,
              value: '',
              input_type:'input',
              options: [],
            inputValue: other,
            optionsValue: []
          };
          }
          if(data.hasOwnProperty('pile_no')){
           this.getLocationItem('pile_no',this.formData.length,data.pile_no)
          }
        })
      }else if(item.label.indexOf('Location of Work')>=0){
        this.initAddressData()
      }
    })
    
  }
   public ngAfterViewInit(){
  
   }
  //渲染CheckBox选框判断
  ArrFn(descriptor:any){
    if(this.textArr.indexOf(descriptor.label)>-1){
      return true
    }else{
      return false
    }
    
  }
  loadDefault() {
    let sign = this.group.members.find( (item:any) => {
      return item.label.indexOf('Signature') !== -1
    })

    if(sign) {

      if(this.workPackage.customDefaultSignValues[sign!.name]){
     
        this.defaultSign =  `${window.location.origin}/api/v3/logical_docs/doc/${
          this.workPackage.customDefaultSignValues[sign!.name]
        }/stream`
         this.isDefoultSign = true
        
    
     
      } else {
        this.defaultSign = this.currentUser.dataset.sign_doc_id.length > 0 ? `${window.location.origin}/api/v3/logical_docs/doc/${
          this.currentUser.dataset.sign_doc_id
        }/stream`: false
        this.isDefoultSign = false
 
     
      }
    }
  }
  initAddressData() {
    return new Promise((resolve,reject)=>{
      this.http.get(`/api/v3/work_packages/lw_list`).subscribe((data: any) => {

        this.formData[0].options = data
        this.formData[0].optionsValue = data
        console.log(this.formData,'this.formData')
        resolve(true)
      });
    })
  }
  // 添加防抖工具函数
  private debounce(func: Function, wait: number) {
    let timeout: any;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  getLocationItem(tag:any,index:any,pile:any){
    this.http.get(`/api/v3/work_packages/lw_items/${tag}`).subscribe((data: any) => {
      console.log(pile,'dataabcd');
      let arr = data.map((item:any)=>{
        return {
          id:item.id,
          title:item.name,
          input_type:'pile_select',
          child:[]
        }
      })
      if(pile){
        this.formData[this.formData.length] = {
          pile_id: pile.id,
          label: 'pile no:',
          value: pile.value,
          input_type:'pile_select',
          options: arr,
          inputValue: '',
          optionsValue: arr
        }
      }else{
      this.formData[index+1] = {
        pile_id: '',
        label: 'pile no:',
        value: '',
        input_type:'pile_select',
        options: arr,
        inputValue: '',
        optionsValue: arr
      }
      }
      // this.formData[0].options = data
      // this.formData[0].optionsValue = data
    });
  }

  // 修改 changeInput 方法
  private debouncedUpdateAddress = this.debounce((descriptor: any) => {
    this.updataAddress(descriptor,'input');
  }, 300); // 300ms 的防抖延迟

  changeInput(descriptor: any) {
    this.debouncedUpdateAddress(descriptor);
  }

  showDialog(index:any,descriptor:any){
    console.log(descriptor,'descriptor');
    
    if(descriptor.field.writable){
      this.showDialogIndex = index
    }
  }
  public showDialogIndex:any = null
  selectAddress(index: number,option:any,descriptor:any): void {
    // 获取当前选中选项的子选项
    let selectedOption =null
    if(this.formData[index].input_type=='select'){
      this.formData[index].id = option.id
      selectedOption = this.formData[index].options.find((res: any) =>
        res.id == this.formData[index].id
      );
    }
    if(this.formData[index].input_type=='pile_select'){
      this.formData[index].pile_id = option.id
      selectedOption = this.formData[index].options.find((res: any) =>
        res.id == this.formData[index].pile_id
      );
    }
    console.log(this.formData[index],'formData[index]',option.id,option.input_type)
    console.log(selectedOption,'index',option,index);
    this.formData[index].value = selectedOption.title
    // if (selectedOption.child.length<=0&&descriptor.field.input_type!='input') {
    //   return;
    // }

    if (index === 0) {
      // 如果是第一级选择,更新第二级选项
      this.formData[1] = {
        id: '',
        label: selectedOption.child[0].label,
        value: '',
        input_type:'select',
        options: selectedOption.child || [] ,
        inputValue: '',
        optionsValue: selectedOption.child || []
      };
    } else if(selectedOption.child.length > 0&& index == 1) {
      // 如果是第二级选择,更新第一级的值
      this.formData[index+1] = {
        id: '' ,
        label: selectedOption.child[0].label,
        value: '',
        input_type:'select',
        options: selectedOption.child || [],
        inputValue: '',
        optionsValue: selectedOption.child || []
      };
    }
    else if(selectedOption.child.length > 0&& index >= 2) {
      // 如果是第二级选择,更新第一级的值
      this.formData[index+1] = {
        id: '' ,
        label: selectedOption.child[0].label,
        value: '',
        input_type:'select',
        options: selectedOption.child || [],
        inputValue: '',
        optionsValue: selectedOption.child || []
      };
    }else if (selectedOption.child.length<=0 && option.input_type=='input'){
      // console.log(option,'option');
      let arr = ['ABWF works','E&M works','Other','Foundation works','Landscape works']
      const label = arr.includes(option.title) ? '' : 'specific works:'
      this.formData[index+1] = {
        id: '',
        label: label,
        value: '',
        input_type:'input',
        options: [],
        inputValue: '',
        optionsValue: []
      };
    }else if(selectedOption.child.length<=0&&option.location_item_tag){
      this.getLocationItem(option.location_item_tag,index,null)
    }
    this.showDialogIndex = null
    this.formData =this.formData.slice(0,index+2)
    console.log(descriptor,'dddd');
    if(descriptor){
      console.log('没进来')
      this.updataAddress(descriptor,option.input_type)
    }
    
  }
  updataAddress(descriptor:any,type:any){
    const params:any = {
      lockVersion: this.workPackage.lockVersion,
      _links: {},
    }
    let paramsdata:any = {}
    params[descriptor.name] = {}
    let selectdata =  this.formData
    .filter((item:any) => item.id) // 过滤掉没有value的项
    .map((item:any) => item.id)
    .join(',')
    paramsdata.id = selectdata
    let otherdata = this.formData.find((item: any) => item.input_type === 'input')
    let pile = this.formData.find((item: any) => item.input_type === 'pile_select')
    if(type=='input'||(otherdata&&otherdata.input_type=='input')){
    let inputdata =''
      // 找到formData中input_type为'input'的项的inputValue
      inputdata = otherdata ? otherdata.inputValue : '';
      paramsdata.other = inputdata
    }
    console.log(type,'type');
    if(type=='pile_select'){
      paramsdata.pile_no = {value:pile.value,id:pile.pile_id,type:pile.input_type}
    }
    params[descriptor.name]['raw'] = JSON.stringify(paramsdata)
    console.log(params,'params');
    
    this.requefunckpost(params,`${window.location.origin}/api/v3/work_packages/${this.workPackage.id}/form`).subscribe((data:any)=>{
      // redata['lockVersion'];//当前最新版本号
      params['lockVersion'] = data._embedded.payload.lockVersion
        this.requefunckpatch(params,`${window.location.origin}/api/v3/work_packages/${this.workPackage.id}`).subscribe((res:any)=>{
          this.workPackage.lockVersion = res.lockVersion
          this.workPackage.customDefaultSignValues = res.customDefaultSignValues
          this.handlerCheck.eventBus.emit('workpackgeChange')
        })
    })
  }
  inputChange(index:any){
    this.formData[index].optionsValue = this.formData[index].options.filter((item:any)=>{
      return item.title.toLowerCase().indexOf(this.formData[index].inputValue.toLowerCase()) != -1
    })
    console.log(this.formData,'this.formData[index]');
    
  }

  ValueContainerContentStyle(descriptor:any) {


      return {'border-color':(this.workPackage.id == 'new' || this.userquanx(descriptor.name)) || this.Tip() ? '#e3e3e3':'rgba(6, 150, 215, 1)', 'cursor': this.workPackage.id == 'new'?'auto':'pointer'}

  
}
  checkRELOWLS() {
    let checkGround = this.groupedFields.find( (item:any) => {
      return item.name == 'Inspection detail'
    }).members
    this.check = checkGround.some( (res:any) => {
       return res.field.value === true
    })
  }
  ValueContainerContentIconStyle(descriptor:any,check?:any) {
      return {'background-color':(this.workPackage.id == 'new' || this.userquanx(descriptor.name)) || this.Tip() ? '#e3e3e3':'rgba(6, 150, 215, 1)', 'cursor': this.workPackage.id == 'new'?'auto':'pointer'}
  }

//INSPECTION DETAIL 选项卡中checkBox点击事件
  checkTrue($event:any){
    let that = this;

    let name =  this.numer.find( (item:any) => {
      return $event.target.id == item.id
    }).name //每次点击的文本
    this.checlist[name] = $event.target.checked
    this.checkFun.run()
    //绑定服务
    let redata:any = {lockVersion: this.workPackage.lockVersion, _links: {},}
    //当前选中选项卡
    let reid:any = $event.target.id
    //是否被选中
    redata[reid] = $event.target.checked
    this.requefunckpost(redata,`${window.location.origin}/api/v3/work_packages/${this.workPackage.id}/form`).subscribe((data:any)=>{
      // redata['lockVersion'];//当前最新版本号
      redata['lockVersion'] = data._embedded.payload.lockVersion
        this.requefunckpatch(redata,`${window.location.origin}/api/v3/work_packages/${this.workPackage.id}`).subscribe((res:any)=>{
          this.workPackage.lockVersion = res.lockVersion
          this.workPackage.customDefaultSignValues = res.customDefaultSignValues
          this.handlerCheck.eventBus.emit('workpackgeChange')
        })
    })
  }
  
  detailSet() {
    let list: (() => void)[] = []
    return {
      pushList: (trueList:Array<number>, disableList:Array<number>)=> {
        list.push( () => {
          let isCheck = true
          trueList.forEach( (index) => {
    
            isCheck = isCheck && this.checlist[this.itemlabel[index]]
          })
          if(isCheck) {
            disableList.forEach( (index) => {
              this.checkdiabale[this.itemlabel[index]] = true
            })
          }
        })
      },
      run:() =>{
        this.itemlabel.forEach( (label) => {
          this.checkdiabale[label] = false
        })
        list.forEach( (item:any) => {

          item()
        })
      },
      setCheckList: () => {
        let checkGround = this.groupedFields.find( (item:any) => {
          return item.name == 'Inspection detail'
        }).members
        checkGround.forEach( (res:any) => {
            this.checlist[res.field.label] = res.field.value
        })
      }
    }
  }
  //动态添加id标识
  numerdata(descriptor:any){
    let index = this.numer.find((data:any) => {
      return data.id == descriptor.name
    });
    if(!index)this.numer.push({id:descriptor.name,name:descriptor.label})
    return descriptor.name
  }

  constructor(readonly I18n:I18nService,
              public wpeditForm:EditFormComponent,
              protected injector:Injector,
              private http: HttpClient,
              private changeDetectorRef:ChangeDetectorRef,
              public handlerCheck: HandlerCheckObserveService, ) {
  }

  public trackByName(_index:number, elem:{ name:string }) {
    return elem.name;
  }

  /**
   * Hide read-only fields, but only when in the create mode
   * @param {FieldDescriptor} field
   */
  public shouldHideField(descriptor:FieldDescriptor) {
    const field = descriptor.field || descriptor.fields![0];
    return this.wpeditForm.editMode && !field.writable;
  }


  displaysign(index:any){
    this.groupitem = index
    
    if(this.workPackage.id == 'new'||this.workPackage[this.group.members[index].name] || this.userquanx(this.group.members[index].name) || this.Tip())return
    this.dipslesignature = true
    
  }

  displaymodel(name:string){
    if(this.workPackage.type.name == "RISC" ||this.workPackage.type.name ==  "RISC(inspection)" ||this.workPackage.type.name ==  "RISC(EM)"){
      return name.indexOf('Signature')<0 ? true : false
    }else{
      return true
    }    
  }
  locationofwork(name:string){
    return name.indexOf('Location of Work')>-1 && this.workPackage.type.name == 'RISC(inspection)' ? true : false
  }

  isSign (index:any) {
    return !(this.workPackage.id == 'new'||this.workPackage[this.group.members[index].name] || this.userquanx(this.group.members[index].name) || this.Tip())
  }
  closeView(img:any){
    
    this.signatureImage = img
    
    if(!img) {
      this.dipslesignature = false 
      return
    }
  
    this.groupimgdata[this.group.members[this.groupitem].name] = img
    
    let blob = this.dataURLtoBlob(img)
    this.signloding = img ? this.groupitem : ''
    var files = new File([blob], 'drawComplete.png', { type: blob.type })
    let file = new FormData();
    file.append('file', files);
    file.append('prefix', 'risc');
    this.dipslesignature = false
    let projectId:any = document.querySelector('meta[name=current_project]')

    this.http.get(`/api/v3/work_packages/${this.workPackage.id}`).subscribe((worlpageres:any)=>{
      this.requefunckpost(file,`${this.apiPrefix}/projects/${projectId.dataset.projectId}/sign`).subscribe((imgdata:any)=>{
        let requedata:any = {lockVersion: worlpageres.lockVersion,_links: {}}
        
        // this.requefunckpost(requedata,`${this.apiPrefix}/work_packages/${this.workPackage.id}/form`).subscribe((data:any)=>{
          requedata[this.group.members[this.groupitem].name] = imgdata.doc_id

          this.requefunckpatch(requedata,`${this.apiPrefix}/work_packages/${this.workPackage.id}`).subscribe((res:any)=>{
              this.workPackage[this.group.members[this.groupitem].name] = res[this.group.members[this.groupitem].name]
              this.workPackage.lockVersion = res.lockVersion
              this.signloding = null
              this.changeDetectorRef.markForCheck();
              this.changeDetectorRef.detectChanges();
              console.log('wokerpage都掉这里的接口吗');
              this.handlerCheck.eventBus.emit('showDialog')
          })
        })
      // })
    })
    this.groupimgdata[this.group.members[this.groupitem].name] = img     
  }

  dataURLtoBlob(dataurl:any) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  urlsubscribe(){
    
  }

  checkDefaultSignChange(name:any) {
    if(this.isDefoultSign) {
      this.http.post(
        `/api/v3/work_packages/${this.workPackage.id}/custom_fields/set_defauls_sign`,
        {
          custom_sign_field_name: name,
          custom_sign_field_default_value: this.currentUser.dataset.sign_doc_id
        },
        {
          withCredentials: true,
          headers: this.requestHeader
        }
      ).subscribe( (res) => {
   
      })
    } else {
      this.http.post(
        `/api/v3/work_packages/${this.workPackage.id}/custom_fields/set_defauls_sign`,
        {
          custom_sign_field_name: name,
          custom_sign_field_default_value: ''
        },
        {
          withCredentials: true,
          headers: this.requestHeader
        }
      ).subscribe( (res) => {
    
      })
    }
  }

  userquanx(name:any, type?:any){
    // if(this.okselect) return false
    let signdata:any = this.group
    // console.log(signdata)
    if(this.is_project_admin && type == 'Inspection') {
      return false
    }
    if(!this.group.status.find( (item:any) => {
      return item.id == this.workPackage.status.id
    }) ) {
      if(this.is_project_admin && type == 'Inspection') {
     
      } else {

        return true
      }
    }
    if(this.customFieldGroupPrincipal.length == 0) return true
    if(!signdata.sign.real_principal_name){
      let index = this.customFieldGroupPrincipal.find((res:any)=>{
        return `${res.lastname} ${res.firstname}` == signdata.sign.principal_name
      })
      if(!index) return true
      return index.mail != this.currentUser.dataset.mail
    }else{
      let index = this.customFieldGroupPrincipal.find((res:any)=>{
        return `${res.lastname} ${res.firstname}` == signdata.sign.real_principal_name
      })
      if(!index) return true
      return index.mail != this.currentUser.dataset.mail 
    }

  }

  //下拉框下面的文字
  dipssignuser(name:any){
    let signdata:any = this.group
    if(signdata.sign.real_principal_name && signdata.sign.principal_name) return ''
    let index = this.customFieldGroupPrincipal.find((res:any)=>{
      return `${res.lastname} ${res.firstname}` == signdata.sign.principal_name || `${res.lastname} ${res.firstname}` == signdata.sign.real_principal_name
    })
    if(!index)return `${signdata.sign.principal_name}`
    let position = index.position?`[${index.position}]` : ''
    return signdata.sign.real_principal_name? `${position}${signdata.sign.real_principal_name}` : `${position}${signdata.sign.principal_name}`
    // if(signdata.sign.type == 'User'){

    // }else{
    //   return signdata.sign.real_principal_name? signdata.sign.real_principal_name :`Please assign to a specific User instead of a User Group for signature purpose.`
    // }
    // if(this.signusername) {
    //   let username = this.signusername.sign_custom_fields.find((res:any)=>{
    //     return 'customField'+res.id == name
    //   })
    //   return username.responsible.name
    // }

  }


  get_custom_field_group_available_principal(element:any){
      this.http.get(`/api/v3/work_packages/${this.workPackage.id}/custom_field_group_available_principal?custom_field_group_name=${element}`).subscribe((res:any)=>{
        this.customFieldGroupPrincipal= res.principals
        let panduan = true
        let elementpadnuan = true
        let name = ''

         
          res.principals.forEach((element:any, i:any) => {
            if(i == 0){
              if(element.lastname || element.firstname) {

                name = element.lastname + element.firstname
              } else {
                name += element.name 
              }
            } else {
              if(element.lastname || element.firstname) {

                name = name + ','+ element.lastname + element.firstname
              } else {
                name += ',' + element.name 
              }
            }
          });
        
        for(let i = res.principals.length -1 ; i>=0 ; i--) {
          if(res.principals[i].hasOwnProperty('users')) {
            let users: Array<any> =  res.principals[i].users
       
            res.principals.splice(i + 1,0, ...users)
          }
        }
        res.principals.forEach((prinres:any, i: any) => {
          
          if(panduan){
            if(this.group.real_principal_name == `${prinres.lastname} ${prinres.firstname}` ||  (this.group.real_principal_name == `${prinres.lastname} ${prinres.firstname}`.trim() || this.group.real_principal_name == prinres.name) ){
              this.selectCustomFieldGroup = prinres.id
              panduan = false
            } 
            if(!this.group.real_principal_name && (this.group.principal_name == `${prinres.lastname} ${prinres.firstname}` ||  (this.group.principal_name == `${prinres.lastname} ${prinres.firstname}`.trim() || this.group.principal_name == prinres.name)) ) {
              this.selectCustomFieldGroup = prinres.id
              panduan = false
            }
            // else if(element !== 'FOR Contractor: Submission detail'){
            //   if(elementpadnuan){
            //     this.selectCustomFieldGroup = null
            //     //每一个子分类的选项
            //     this.customFieldGroupPrincipal.unshift({id:null,login:this.group.principal_name})
            //     elementpadnuan = false
            //   }
              
            // }
          }
        });
        if(panduan){
          
   
          res.principals.unshift({
            id:null,
            login: name
          })
        }
      })
  }
  selectName(item:any){
    if(item.id){
      //item 中有id的是人，需要添加职位
      let position = item.position? '['+item.position+']' : ''
      if(item.firstname || item.lastname) {

        return `${position} ${item.firstname} ${item.lastname}`
      } else{
        return `${position} ${item.name} `
      }
    }else{
      //没有id的是组
      return item.login
    }
    
  }

  //签名错误
  userzhuPlease(){
    let signdata:any = this.group
    const currentUser: any = document.querySelector('meta[name=current_user]');
    if(signdata.sign.real_principal_name){
      let index = this.customFieldGroupPrincipal.find((res:any)=>{
        return `${res.lastname} ${res.firstname}` == signdata.sign.real_principal_name
      })
      if(!index) return true
      return false
    }else{
      return false
    }
    
  }
  Tip() {
    let check = false
    let checkGround = this.groupedFields.find( (item:any) => {
      return item.name == 'Inspection detail'
    }).members
    checkGround.forEach( (res:any) => {
      if(res.field.value == true){
        check = true
      }
    })
      return (this.group.name ==='Form returned and signed by RE/IOW/LS') &&  !check
  }

  //handler下拉框选项改变，重新刷新一次页面
  changesCustomFieldGroup(){
    let customField = this.customFieldGroupPrincipal.find( (item:any) => {
   
      return item.id == Number(this.selectCustomFieldGroup)
    })
    let file = {custom_field_group_name:this.group.name,principal_id:this.selectCustomFieldGroup} as any
    if(customField.type) {
      file.principal_type =  customField.type
    }
    this.requefunckpatch(file,`${this.apiPrefix}/work_packages/${this.workPackage.id}/change_custom_field_group_principal`).subscribe((res:any)=>{
      if(res == 200){
        this.http.post(
          `/api/v3/work_packages/${this.workPackage.id}/custom_fields/set_defauls_sign`,
          {
            custom_sign_field_name: name,
            custom_sign_field_default_value: ''
          },
          {
            withCredentials: true,
            headers: this.requestHeader
          }
        ).subscribe( (res) => {
      
          this.handlerCheck.eventBus.emit('handleChange')
        })
      }
    })
  }

  requefunckpatch(data: any,url:any){
    return this.http.patch(
      url,
      data,
      {
        withCredentials: true,
        headers: this.requestHeader
      }
    );
  }

  requefunckpost(data: any,url:any){
    return this.http.post(
      url,
      data,
      {
        withCredentials: true,
        headers: this.requestHeader
      }
    );
  }

  requefunckget(url:any){
    return this.http.get(
      url,
      {}
    );
  }


}
