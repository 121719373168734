<div class="container">
  <div class="headline">
    <div class="headline-title">
      <!-- {{ text.title }} -->
    </div>
<!--    <div class="headline-nav">-->
<!--      <div id="newModule" class="headline-nav-btn" (click)="addWidget.widget(areas.gridGaps[0])">-->
<!--        <i class="iconfont icon-jia"></i>-->
<!--        <span> New Module </span>-->
<!--      </div>-->
<!--      <div id="modify" class="headline-nav-btn" (click)="areas.toggleHelpMode()"-->
<!--      [ngClass]="{'-active': areas.inHelpMode}">-->
<!--        <i class="iconfont icon-shangxia"></i>-->
<!--        <span> Modify </span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <project-list [projectList]="projectList" [loadingList]="loadingList"></project-list>
</div>
<div class="back"  *ngIf="showBack">
  <div class="card">
      <div [innerHTML]="html"></div>
    <div class="btn" (click)="close()">
      <p class="text">OK</p>
    </div>
  </div>
</div>
<div class="container">
<!--  <grid *ngIf="grid" [grid]="grid"></grid>-->
<!--  <overview *ngIf="projectList" [projectList]="projectList"></overview>-->
</div>
