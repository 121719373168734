import { Component, OnInit, Input } from '@angular/core';
import { PathHelperService } from "core-app/modules/common/path-helper/path-helper.service";

@Component({
  selector: 'project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.sass']
})
export class ProjectListComponent implements OnInit {
  // 项目数据
  @Input() projectList: any;
  @Input() loadingList: boolean[];
  constructor(
    readonly pathHelper: PathHelperService, ) {
  }
  ngOnInit() {

  }

  public projectPath(link: string) {
    return this.pathHelper.projectPath(link.toLowerCase());
  }

  public workPackagesPath(link: string, state: string) {
    return  ''
    return `${this.pathHelper.projectPath(link.toLowerCase())}/work_packages?query_props={"c":["id","subject","type","status","priority","assignee"],"tv":false,"hl":"none","hi":true,"g":"","t":"createdAt:desc","f":[{"n":"status_name","o":"=","v":["${state}"]}],"pa":1,"pp":20}`;
  }
}
